import { useEffect, useState } from "react";
import { getProfileValidation } from "../../turnaroundProfileUtils";
import {
  deepCopy,
  getByFieldValue,
  getRelativeTimeString,
  isEmptyList,
  isNullOrUndefined,
} from "../../utils";
import moment from "moment-timezone";
import DispatchOperation from "./DispatchOperation";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import DispatchInfo from "./DispatchInfo";
import { useTranslation } from "react-i18next";
import { getPositions, getTurnaroundTemplates } from "../../api";
import { getCriteriaForPositions } from "../../mapUtils";
import TemplateSelectorModal from "./TemplateSelectorModal";
import DispatchOverrides from "./DispatchOverrides";
import DispatchOverridesEditor from "./DispatchOverridesEditor";
import Keys from "../Keys";

const DISPATCH_EDITOR_MODE = {
  OPERATIONS: "OPERATIONS",
  OVERRIDES: "OVERRIDES",
};
function DispatchEditor(props) {
  const {
    turnaroundProfileConfiguration,
    turnaroundToConfigure,
    approveAction,
    cancelAction,
  } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { users, positions, turnarounds, turnaroundTemplates } = mainContext;
  const isDispatched = turnaroundToConfigure.isDispatched;
  const [readonlyMode, setReadonlyMode] = useState(!isDispatched);
  const [editorMode, setEditorMode] = useState(DISPATCH_EDITOR_MODE.OPERATIONS);
  const [configurationToEdit, setConfigurationToEdit] = useState(
    turnaroundProfileConfiguration
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [isTemplateSelectorModalOpen, setIsTemplateSelectorModalOpen] =
    useState(false);

  const mNow = moment().tz(turnaroundToConfigure.airportTimezone);
  const mApproveBy = !isNullOrUndefined(turnaroundToConfigure.approvalDeadline)
    ? moment(turnaroundToConfigure.approvalDeadline).tz(
        turnaroundToConfigure.airportTimezone
      )
    : null;

  const isApproved = turnaroundToConfigure.isApproved;
  const automaticDispatchTime = `${getRelativeTimeString(mApproveBy, mNow)}`;
  const monitor = turnaroundToConfigure.originalMonitor;
  const profileValidation = getProfileValidation(configurationToEdit);

  useEffect(() => {
    getTurnaroundTemplates(dispatch);
    const criteria = getCriteriaForPositions([], [], false, false);
    getPositions(dispatch, criteria);
  }, [dispatch]);

  function handleOperationChange(value) {
    const configurationToUpdate = deepCopy(configurationToEdit);
    const operations = configurationToUpdate.turnaroundOperations;
    let updated = false;
    for (let i = 0; !updated && i < operations.length; i++) {
      const operation = operations[i];
      if (operation.uuid === value.uuid) {
        // Replace the operation with the updated operation
        configurationToUpdate.turnaroundOperations[i] = value;
        updated = true;
      }
    }
    setConfigurationToEdit(configurationToUpdate);
    setHasChanges(true);
  }

  const dismissConfirmation = () => {
    dispatch({
      type: "setConfirmation",
      confirmation: null,
    });
  };

  const parentTemplate =
    !isEmptyList(turnaroundTemplates) &&
    !isNullOrUndefined(turnaroundToConfigure?.turnaroundTemplateUuid)
      ? getByFieldValue(
          turnaroundTemplates,
          "uuid",
          turnaroundToConfigure.turnaroundTemplateUuid
        )
      : null;

  const hasAvailableLabels = !isEmptyList(parentTemplate?.turnaroundLabels);

  const ctrlKeyActions = {
    a: () => {
      // Readonly mode on by default, but can turn on with this hotkey
      setReadonlyMode((prev) => !prev);
    },
  };
  return (
    <div className="dispatch-editor">
      <div className="dispatch-editor-header">
        <div className="dispatch-editor-module">
          <div className="dispatch-editor-header-content">
            <div className="dispatch-editor-header-info">
              <h4>{t("configure_dispatch")}</h4>
              <div>
                {!isDispatched && (
                  <span>
                    {t("automatic_dispatch")} {automaticDispatchTime}
                  </span>
                )}
                {isDispatched && (
                  <span>
                    {t("dispatched")} {automaticDispatchTime}
                  </span>
                )}
              </div>
              <div>
                {hasAvailableLabels && (
                  <div>
                    <DispatchOverrides
                      turnaroundInfo={turnaroundToConfigure}
                      onEditClick={() => {
                        setEditorMode(DISPATCH_EDITOR_MODE.OVERRIDES);
                      }}
                      showEditButtons={!isDispatched}
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              <DispatchInfo turnaroundInfo={turnaroundToConfigure} />
            </div>
            <div>
              <div className="actions">
                <button
                  className="secondary"
                  onClick={() => {
                    if (!hasChanges) {
                      cancelAction();
                    } else {
                      dispatch({
                        type: "setConfirmation",
                        confirmation: {
                          message: (
                            <div className="confirmation-message">
                              <div>{t("exit_dispatch")}</div>
                              <span>{t("exit_dispatch_detail")}</span>
                            </div>
                          ),
                          actionOK: () => {
                            cancelAction();
                            dismissConfirmation();
                          },
                          actionCancel: () => {
                            dismissConfirmation();
                          },
                          actionLabel: t("yes_exit"),
                          cancelLabel: t("continue_editing"),
                        },
                      });
                    }
                  }}
                >
                  {t("cancel")}
                </button>
                <button
                  className="green"
                  disabled={!hasChanges}
                  onClick={() => {
                    if (!profileValidation.isValid) {
                      const actionLabel = !isApproved
                        ? t("approve_dispatch")
                        : t("save_dispatch");
                      dispatch({
                        type: "setConfirmation",
                        confirmation: {
                          message: (
                            <div className="confirmation-message">
                              <div>{t("confirm_dispatch")}</div>
                              <span>{profileValidation.validationMessage}</span>
                            </div>
                          ),
                          actionOK: () => {
                            approveAction(configurationToEdit);
                            dismissConfirmation();
                          },
                          actionCancel: () => {
                            dismissConfirmation();
                          },
                          actionLabel: actionLabel,
                          cancelLabel: t("continue_editing"),
                        },
                      });
                    } else {
                      approveAction(configurationToEdit);
                    }
                  }}
                >
                  {!isApproved && t("approve_dispatch")}
                  {isApproved && t("save_dispatch")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {editorMode === DISPATCH_EDITOR_MODE.OVERRIDES && (
        <div className="dispatch-editor-container">
          <DispatchOverridesEditor
            onCancel={() => {
              setEditorMode(DISPATCH_EDITOR_MODE.OPERATIONS);
            }}
            onApply={() => {
              setEditorMode(DISPATCH_EDITOR_MODE.OPERATIONS);
            }}
            turnaroundInfo={turnaroundToConfigure}
            parentTemplate={parentTemplate}
          />
        </div>
      )}
      {editorMode === DISPATCH_EDITOR_MODE.OPERATIONS && (
        <>
          <div className="dispatch-editor-container">
            <div className="dispatch-editor-content">
              {!isNullOrUndefined(configurationToEdit?.turnaroundOperations) &&
                configurationToEdit.turnaroundOperations.map((operation) => (
                  <DispatchOperation
                    key={operation.uuid}
                    operation={operation}
                    monitor={monitor}
                    onOperationChange={(value) => {
                      handleOperationChange(value);
                    }}
                    users={users}
                    positions={positions}
                    turnarounds={turnarounds}
                    readonlyMode={readonlyMode}
                  />
                ))}
            </div>
          </div>
        </>
      )}
      <TemplateSelectorModal
        isOpen={isTemplateSelectorModalOpen}
        onClose={() => {
          setIsTemplateSelectorModalOpen(false);
        }}
        turnaroundTemplates={turnaroundTemplates}
        turnaroundToConfigure={turnaroundToConfigure}
      />
      <Keys ctrlKeyActions={ctrlKeyActions} />
    </div>
  );
}

export default DispatchEditor;
