import { useTranslation } from "react-i18next";
import { useMainDispatchContext } from "../../MainContext";
import { ReactComponent as Arrival } from "../../assets/turnarounds/arrival.svg";
import { ReactComponent as Departure } from "../../assets/turnarounds/departure.svg";
import { EMPTY_STAND, EMPTY_TIME } from "../../constants";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import { formatTime, isBlank, isNullOrUndefined } from "../../utils";
import FlightProgress from "../FlightProgress";

function FlightInfoModule(props) {
  const { t } = useTranslation();
  const { flightInfo, showMapInfo } = props;
  const dispatch = useMainDispatchContext();

  const airportTimezone = flightInfo.airportTimezone;
  const timeValue = flightInfo.isInbound
    ? flightInfo.landing
    : flightInfo.takeoff;
  const timeValueDisplay = !isNullOrUndefined(timeValue)
    ? formatTime(timeValue, airportTimezone, true)
    : EMPTY_TIME;
  const timeDisplay = flightInfo.isInbound ? t("arrival") : t("departure");

  // Stand label should change based on Inbound/Outbound and whether it is snapped
  const standDisplayLabel = !isBlank(flightInfo.snappedStandName)
    ? t("current_stand")
    : flightInfo.isInbound
    ? t("assigned_arr_stand_abbr")
    : t("assigned_dep_stand_abbr");

  // Stand value should be snapped if available, otherwise assigned
  const standDisplay = !isBlank(flightInfo.snappedStandName)
    ? flightInfo.snappedStandName
    : !isBlank(flightInfo.assignedStandName)
    ? flightInfo.assignedStandName
    : EMPTY_STAND;

  let flightInfoTitle = "";
  // Show additional info if enabled
  if (showMapInfo) {
    if (flightInfo?.position?.onGround) {
      flightInfoTitle = "On ground";
    } else {
      flightInfoTitle = `Distance to arrival: ${
        !isNullOrUndefined(flightInfo.distanceToArrival)
          ? `${Math.round(flightInfo.distanceToArrival)} miles`
          : "N/A"
      }`;
    }
  }
  return (
    <div
      className="overlay-module"
      onClick={() => {
        dispatch({
          type: "setShowOnMap",
          value: {
            markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
            item: {
              registration: flightInfo.registration,
            },
          },
        });
      }}
      title={flightInfoTitle}
      data-id={flightInfo.uuid}
    >
      <div className="overlay-module-box">
        <div className="flight-info">
          <div>
            {flightInfo.isInbound && (
              <>
                <Arrival />
              </>
            )}
            {flightInfo.isOutbound && (
              <>
                <Departure />
              </>
            )}
          </div>
          <div className="value-pair large-value">
            <label>{t("flight_non_cap")}</label>
            <div className="value">{flightInfo.flightName}</div>
          </div>
          <div className="value-pair large-value">
            <label>{timeDisplay}</label>
            <div className="value">{timeValueDisplay}</div>
          </div>
          <div className="value-pair large-value">
            <label>{standDisplayLabel}</label>
            <div className="value">{standDisplay}</div>
          </div>
        </div>
        <FlightProgress flightInfo={flightInfo} />
      </div>
    </div>
  );
}

export default FlightInfoModule;
