import "./styles.css";
import { ReactComponent as Battery } from "../../assets/tracker/battery.svg";
import { ReactComponent as Sat } from "../../assets/tracker/sat.svg";
import { useTranslation } from "react-i18next";

function TrackerStatus(props) {
  const { t } = useTranslation();

  const { hasPower, battery, satStrengthPercent } = props;

  const batteryFill = {
    background: `linear-gradient(to right, #B0B7C3 ${battery}%,  transparent ${battery}%)`,
  };

  return (
    <div className="tracker-status">
      <div>
        <div className="cell">
          <div>
            <div className="cell-icon">
              <div className="cell-icon-battery">
                <div
                  className="cell-icon-battery-fill"
                  style={batteryFill}
                ></div>
                <Battery />
              </div>
            </div>
            <div className="cell-value">
              {hasPower ? t("power_abbr").toUpperCase() : `${battery}%`}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="cell">
          <div>
            <div className="cell-icon">
              <div className="cell-icon-sat">
                <Sat />
              </div>
            </div>
            <div className="cell-value">{`${satStrengthPercent}%`}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TrackerStatus;
