import { useTranslation } from "react-i18next";
import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { ReactComponent as LeftArrow } from "../../assets/arrow-left-sm.svg";
import { EMPTY_TIME, MAP_RIGHT_PANEL_MODES } from "../../constants";
import {
  OperationOffsetType,
  TurnaroundOperationStatus,
} from "../../turnaroundUtils";
import { getUserInfo, isUserOnMap } from "../../userUtils";
import {
  deepCopy,
  formatTime,
  getByFieldValue,
  getMapFromList,
  isBlank,
  isNullOrUndefined,
  scrollElementIntoView,
  sortByField,
} from "../../utils";
import TurnaroundCrew from "./TurnaroundCrew";
import TurnaroundVehicle from "./TurnaroundVehicle";
import TurnaroundVehicleSlot from "./TurnaroundVehicleSlot";
import { useEffect, useMemo, useState } from "react";
import StatusBadge from "../StatusBadge";
import TurnaroundCrewSlot from "./TurnaroundCrewSlot";
import LocatePanel from "./LocatePanel";
import { useMainDispatchContext } from "../../MainContext";
import { MAPINFO_MARKER_TYPE } from "../../mapUtils";
import {
  getTurnaroundDetails,
  getTurnaroundsSummary,
  patchTurnaroundOperationAckStatus,
  patchTurnaroundProfile,
} from "../../api";
import CriticalMessages from "../Confirmation/CriticalMessages";

const TurnaroundOperationPanelMode = {
  DEFAULT: "DEFAULT",
  LOCATOR: "LOCATOR",
};

function TurnaroundOperationPanel(props) {
  const {
    onClose,
    selectedOperation,
    airportTimezone,
    selectedVehicleUuid,
    selectedUserUuid,
    onSelectVehicle,
    onSelectUser,
    onAddCrewMember,
    vehicles,
    positions,
    users,
    showActions,
    onBack,
    showBackButton,
    selectedTurnaround,
    turnarounds,
    showUnassignCrew,
  } = props;
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const originalProfile = selectedTurnaround?.originalProfile;
  const { gseRecords, userRecords, turnaroundRequirements } = selectedOperation;
  const [isSavingAckStatus, setIsSavingAckStatus] = useState(false);
  const [operationPanelMode, setOperationPanelMode] = useState(
    TurnaroundOperationPanelMode.DEFAULT
  );
  const [locateGseRequirement, setLocateGseRequirement] = useState(null);
  const [isSavingUnassignment, setIsSavingUnassignment] = useState(false);
  const crewAssignments = [];
  const vehicleRequirementsByName = {};

  const gseRecordsList = !isNullOrUndefined(gseRecords)
    ? deepCopy(gseRecords)
    : [];

  // Create user lookup
  const usersByUuid = useMemo(() => {
    return getMapFromList(users, "uuid");
  }, [users]);

  // Handle scrolling to the GSE tile
  useEffect(() => {
    if (!isNullOrUndefined(selectedVehicleUuid)) {
      const containerEl = document.querySelector(
        ".map .map-panel .overlay-panel-content"
      );
      const targetEl = document.querySelector(
        `[data-uuid='${selectedVehicleUuid}'`
      );
      scrollElementIntoView(containerEl, targetEl);
    }
  }, [selectedVehicleUuid]);

  // Handle scrolling to the User tile
  useEffect(() => {
    if (!isNullOrUndefined(selectedUserUuid)) {
      const containerEl = document.querySelector(
        ".map .map-panel .overlay-panel-content"
      );
      const targetEl = document.querySelector(
        `[data-uuid='${selectedUserUuid}'`
      );
      scrollElementIntoView(containerEl, targetEl);
    }
  }, [selectedUserUuid]);

  // Extract all crewAssignments and vehicle requirements
  // Requirements could have both crew and vehicles
  if (!isNullOrUndefined(turnaroundRequirements)) {
    for (let i = 0; i < turnaroundRequirements.length; i++) {
      const turnaroundRequirement = turnaroundRequirements[i];
      if (
        !isNullOrUndefined(turnaroundRequirement.crewAssignments) &&
        turnaroundRequirement.crewAssignments.length > 0
      ) {
        const crewAssignmentsToAdd = turnaroundRequirement.crewAssignments.map(
          (item) => {
            return {
              ...item,
              certificationName: turnaroundRequirement?.certification?.name,
              turnaroundRequirementUuid: turnaroundRequirement?.uuid,
            };
          }
        );
        crewAssignments.push(...crewAssignmentsToAdd);
      }
      if (
        turnaroundRequirement.quantity > 0 &&
        !isBlank(turnaroundRequirement?.certification?.groundVehicleTypeUuid)
      ) {
        // Expecting vehicles for this requirement
        const vehicleName = turnaroundRequirement.certification.name;
        const vehicleTypeUuid =
          turnaroundRequirement.certification.groundVehicleTypeUuid;
        if (isNullOrUndefined(vehicleRequirementsByName[vehicleName])) {
          vehicleRequirementsByName[vehicleName] = {
            uuid: turnaroundRequirement.uuid,
            name: vehicleName,
            vehicleTypeUuid: vehicleTypeUuid,
            expected: 0,
            actual: 0,
          };
        }
        vehicleRequirementsByName[vehicleName].expected =
          vehicleRequirementsByName[vehicleName].expected +
          turnaroundRequirement.quantity;
      }
    }
  }
  // Determine actual number of GSEs by name
  // NOTE: this requires GSE type name matches certification name for this to work
  if (!isNullOrUndefined(gseRecords)) {
    for (let i = 0; i < gseRecords.length; i++) {
      const gseRecord = gseRecords[i];
      const gseName = gseRecord?.groundVehicle?.gseType?.name;
      const vehicleRequirement = !isBlank(gseName)
        ? vehicleRequirementsByName[gseName]
        : null;
      if (!isNullOrUndefined(vehicleRequirement)) {
        vehicleRequirement.actual = vehicleRequirement.actual + 1;
      }
    }
  }
  // Determine all missing vehicle requirements
  const missingVehicleRequirements = [];
  const vehicleRequirements = Object.values(vehicleRequirementsByName);
  for (let i = 0; i < vehicleRequirements.length; i++) {
    const vehicleRequirement = vehicleRequirements[i];
    const missingCount =
      vehicleRequirement.expected - vehicleRequirement.actual;
    for (let j = 0; j < missingCount; j++) {
      // Each vehicle type may have more than one needed
      missingVehicleRequirements.push({
        ...vehicleRequirement,
        id: `${vehicleRequirement.uuid}_${j}`, // Id for UI only
      });
    }
  }

  const userRecordsByUserUuid = {};
  if (!isNullOrUndefined(userRecords)) {
    for (let i = 0; i < userRecords.length; i++) {
      const userRecord = { ...userRecords[i] };
      if (!isNullOrUndefined(userRecord?.user.uuid)) {
        userRecordsByUserUuid[userRecord?.user.uuid] = userRecord;
      }
    }
  }

  // Crew assignments include Accepted + Pending
  const assignedCrew = [];
  if (!isNullOrUndefined(crewAssignments)) {
    // Construct an info object to associate the crew assignment and userRecords
    for (let i = 0; i < crewAssignments.length; i++) {
      const crewAssignent = { ...crewAssignments[i] };
      if (!isBlank(crewAssignent.userUuid)) {
        const user = usersByUuid[crewAssignent.userUuid];
        const userRecord = userRecordsByUserUuid[crewAssignent.userUuid];
        crewAssignent.userRecord = userRecord;
        crewAssignent.fullName = `${user.firstName} ${user.lastName}`;
        crewAssignent.user = user;
        crewAssignent.dispatchedAt = new Date().toISOString();
        assignedCrew.push(crewAssignent);
      }
    }
    sortByField(assignedCrew, "fullName");
  }

  // Determine if any missing crew requirements (Allocated but not assigned)
  const missingCrewRequirements = [];
  if (
    !isNullOrUndefined(originalProfile) &&
    !isNullOrUndefined(selectedOperation) &&
    selectedOperation.enabled
  ) {
    const operationToCheck = getByFieldValue(
      originalProfile.turnaroundOperations,
      "uuid",
      selectedOperation.uuid
    );

    const requirements = !isNullOrUndefined(operationToCheck)
      ? operationToCheck.turnaroundRequirements
      : [];
    for (let i = 0; i < requirements.length; i++) {
      const requirement = requirements[i];
      const isCrewRequirement = isNullOrUndefined(
        requirement.groundVehicleTypeUuid
      );
      const crewRequired = isCrewRequirement ? requirement.quantity : 0;
      const crewAssignments = !isNullOrUndefined(requirement.crewAssignments)
        ? requirement.crewAssignments
        : [];
      const crewAssignmentsNeeded =
        crewAssignments.length < crewRequired
          ? crewRequired - crewAssignments.length
          : 0;
      if (crewAssignmentsNeeded > 0) {
        // Add them in one by one so we can render in the UI
        for (let j = 0; j < crewAssignmentsNeeded; j++) {
          missingCrewRequirements.push({
            id: `${requirement.uuid}_${j}`, // Only for UI id
            requirement: requirement,
          });
        }
      }
    }
  }
  const hasMissingCrewRequirements = missingCrewRequirements.length > 0;
  const hasAssignedCrew =
    !isNullOrUndefined(assignedCrew) && assignedCrew.length > 0;

  const isInbound =
    selectedOperation?.offsetTypeId === OperationOffsetType.INBOUND;
  const completedTime = selectedOperation?.completedTime;
  const initiatedTime = selectedOperation?.initiatedTime;
  const hasGseRecords = gseRecordsList?.length > 0;
  const hasMissingVehicleRequirements = missingVehicleRequirements?.length > 0;

  let gseRecordsToRender = [];
  if (hasGseRecords) {
    const gseRecordsInProgress = [];
    const gseRecordsCompleted = [];
    const gseRecordsNotStared = [];
    for (let i = 0; i < gseRecordsList.length; i++) {
      const gseRecord = gseRecordsList[i];
      if (!isBlank(gseRecord?.startTime) && !isBlank(gseRecord?.endTime)) {
        // Complete
        gseRecordsCompleted.push(gseRecord);
      } else if (!isBlank(gseRecord?.startTime)) {
        gseRecordsInProgress.push(gseRecord);
      } else {
        gseRecordsNotStared.push(gseRecord);
      }
    }

    sortByField(gseRecordsCompleted, "groundVehicle.name");
    sortByField(gseRecordsInProgress, "groundVehicle.name");
    sortByField(gseRecordsNotStared, "groundVehicle.name");
    gseRecordsToRender = gseRecordsInProgress
      .concat(gseRecordsNotStared)
      .concat(gseRecordsCompleted);
  }

  const isInProgress =
    selectedOperation?.status.statusId ===
      TurnaroundOperationStatus.IN_PROGRESS ||
    selectedOperation?.status.statusId ===
      TurnaroundOperationStatus.IN_PROGRESS_LATE;

  function resetScroll() {
    document
      .querySelector(".map .map-panel .overlay-panel-content")
      .scrollTo(0, 0);
  }

  async function handleUnassignCrew(userInfo) {
    if (isNullOrUndefined(userInfo)) return;
    // NOTE: Profile is required, if not present then can not modify
    if (isNullOrUndefined(selectedTurnaround?.originalProfile)) return;
    setIsSavingUnassignment(false);

    const requirementsForPatch = [];
    const requirementUuid = userInfo?.turnaroundRequirementUuid;
    const requirementToUnassign = getByFieldValue(
      turnaroundRequirements,
      "uuid",
      requirementUuid
    );

    if (!isNullOrUndefined(requirementToUnassign)) {
      // Remove from crew assignments, quantity stays the same
      requirementsForPatch.push({
        userUuidsToRemove: [userInfo.userUuid],
        uuid: requirementToUnassign.uuid,
        quantity: requirementToUnassign.quantity - 1,
      });

      const patchRequest = {
        uuid: selectedTurnaround?.originalProfile?.uuid,
        operations: [],
        requirements: requirementsForPatch,
        parentLabelUuidsToApply: [],
        labelUuidsToRemove: [],
      };
      const result = await patchTurnaroundProfile(dispatch, patchRequest);
      await getTurnaroundDetails(dispatch, selectedTurnaround);
      if (result) {
        let dispatchMessage = `${t("saved_web", {
          name: selectedTurnaround.combinedFlightName,
        })}`;
        dispatch({
          type: "setAlertMessage",
          alertMessage: dispatchMessage,
        });
      }
    }
    setIsSavingUnassignment(false);
  }

  const isOperationInCriticalState =
    selectedOperation?.status?.statusId ===
      TurnaroundOperationStatus.IN_PROGRESS_LATE ||
    selectedOperation?.status?.statusId === TurnaroundOperationStatus.MISSING;
  const criticalStateMessage = selectedOperation?.status?.displayName;
  const ackedAtTime = !isNullOrUndefined(selectedOperation?.ackedAt)
    ? formatTime(selectedOperation?.ackedAt, airportTimezone)
    : null;
  const ackedByUser = !isNullOrUndefined(selectedOperation?.ackedByUser)
    ? getUserInfo(selectedOperation?.ackedByUser).fullName
    : null;

  function handleAckStatus() {
    async function ackCallback() {
      setIsSavingAckStatus(true);
      await patchTurnaroundOperationAckStatus(dispatch, selectedOperation.uuid);
      await getTurnaroundDetails(dispatch, selectedTurnaround);
      await getTurnaroundsSummary(dispatch);
      setIsSavingAckStatus(false);

      // Completed message
      let dispatchMessage = `${t("saved_web", {
        name: selectedTurnaround.combinedFlightName,
      })}`;
      dispatch({
        type: "setAlertMessage",
        alertMessage: dispatchMessage,
      });

      dispatch({
        type: "setConfirmation",
        confirmation: null,
      });
    }
    dispatch({
      type: "setConfirmation",
      confirmation: {
        message: (
          <CriticalMessages
            turnaroundInfo={selectedTurnaround}
            selectedOperation={selectedOperation}
          />
        ),
        actionLabel: t("confirm_non_cap"),
        actionOK: () => {
          ackCallback();
        },
        actionCancel: () => {
          dispatch({
            type: "setConfirmation",
            confirmation: null,
          });
        },
      },
    });
  }

  return (
    <>
      {operationPanelMode === TurnaroundOperationPanelMode.LOCATOR && (
        <>
          <div className="context-panel-content-header">
            <div className="back-btn">
              <div>
                {showBackButton && (
                  <button
                    className="outline-filled"
                    onClick={() => {
                      setOperationPanelMode(
                        TurnaroundOperationPanelMode.DEFAULT
                      );
                      dispatch({
                        type: "setItemsToWhitelist",
                        value: null,
                      });
                      dispatch({
                        type: "setShowOnMap",
                        value: {
                          markerType: MAPINFO_MARKER_TYPE.AIRCRAFT,
                          item: {
                            registration: selectedTurnaround.registration,
                          },
                          isLocateMode: true,
                        },
                      });
                      onSelectVehicle(null);
                      resetScroll();
                    }}
                  >
                    <LeftArrow /> {t("back")}
                  </button>
                )}
              </div>
            </div>
            <div className="close-btn" onClick={onClose}>
              <CloseBtn />
            </div>
          </div>
          <div className="locator-panel-container">
            <LocatePanel
              locateGseRequirement={locateGseRequirement}
              selectedTurnaround={selectedTurnaround}
              selectedVehicleUuid={selectedVehicleUuid}
              vehicles={vehicles}
              positions={positions}
              turnarounds={turnarounds}
            />
          </div>
        </>
      )}
      {operationPanelMode === TurnaroundOperationPanelMode.DEFAULT && (
        <>
          <div className="context-panel-content-header">
            <div className="back-btn">
              <div>
                {showBackButton && (
                  <button className="outline-filled" onClick={onBack}>
                    <LeftArrow /> {t("back")}
                  </button>
                )}
              </div>
            </div>
            <div className="close-btn" onClick={onClose}>
              <CloseBtn />
            </div>
          </div>
          <div className="context-panel-titlebar">
            <h4>
              {selectedOperation.name}
              {isInProgress && (
                <>
                  <div className="status-info-pulse fadeInLoop"></div>
                </>
              )}
            </h4>
            <div className="context-panel-titlebar-details">
              <div>
                <div>
                  <StatusBadge
                    isAllCap={true}
                    isOutlined={true}
                    message={isInbound ? t("inbound") : t("outbound")}
                  />
                </div>
              </div>
            </div>
          </div>
          {isOperationInCriticalState && !isBlank(criticalStateMessage) && (
            <div className="context-panel-critical-section">
              <div>
                <div>
                  <StatusBadge
                    isCritical={true}
                    message={criticalStateMessage}
                  />
                </div>
                <div className="actions">
                  {isOperationInCriticalState &&
                    isNullOrUndefined(ackedByUser) &&
                    isNullOrUndefined(ackedAtTime) && (
                      <button
                        className="outlined"
                        onClick={handleAckStatus}
                        disabled={isSavingAckStatus}
                      >
                        {t("ack_critical")}
                      </button>
                    )}
                  {isOperationInCriticalState &&
                    !isNullOrUndefined(ackedByUser) &&
                    !isNullOrUndefined(ackedAtTime) && (
                      <div>
                        <div className="note">
                          {t("acknowledged_critical_web", {
                            name: ackedByUser,
                            value: ackedAtTime,
                          })}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}

          <div className="context-panel-container">
            <div className="turnaround-content open">
              <div className="turnaround-general">
                <div className="stat">
                  <label>{t("initiated_non_cap")}</label>
                  <span>
                    {!isNullOrUndefined(initiatedTime)
                      ? formatTime(initiatedTime, airportTimezone)
                      : EMPTY_TIME}
                  </span>
                </div>
                <div className="stat">
                  <label>{t("completed_non_cap")}</label>
                  <span>
                    {!isNullOrUndefined(completedTime)
                      ? formatTime(completedTime, airportTimezone)
                      : EMPTY_TIME}
                  </span>
                </div>
              </div>

              {(hasAssignedCrew || hasMissingCrewRequirements) && (
                <>
                  <div className="turnaround-crew-list">
                    <div>
                      <div className="context-panel-section-header">
                        {t("crew")}
                      </div>
                    </div>
                    {hasMissingCrewRequirements &&
                      missingCrewRequirements.map((missingCrewRequirement) => (
                        <TurnaroundCrewSlot
                          key={`${missingCrewRequirement.id}`}
                          missingCrewRequirement={missingCrewRequirement}
                          selected={
                            selectedUserUuid === missingCrewRequirement?.id
                          }
                          onClick={() => {
                            if (!showActions) return;
                            if (
                              !isNullOrUndefined(onSelectUser) &&
                              !isNullOrUndefined(missingCrewRequirement?.id)
                            ) {
                              onSelectUser(
                                missingCrewRequirement?.id,
                                MAP_RIGHT_PANEL_MODES.CREW_ALLOCATIONS
                              );
                            }
                          }}
                          isSelectable={showActions}
                          showActions={showActions}
                        />
                      ))}
                    {assignedCrew &&
                      assignedCrew.map((item) => (
                        <TurnaroundCrew
                          key={item.uuid}
                          crewInfo={item}
                          operationName={selectedOperation?.name}
                          airportTimezone={airportTimezone}
                          selected={selectedUserUuid === item?.user.uuid}
                          showCertifications={true}
                          showActions={showActions}
                          isSelectable={
                            !isNullOrUndefined(onSelectUser) &&
                            isUserOnMap(item?.user, positions)
                          }
                          onClick={() => {
                            dispatch({
                              type: "setItemsToWhitelist",
                              value: null,
                            });
                            if (
                              !isNullOrUndefined(onSelectUser) &&
                              !isNullOrUndefined(item?.user.uuid)
                            ) {
                              onSelectUser(item?.user.uuid);
                            }
                          }}
                          showUnassignCrew={showUnassignCrew}
                          onUnassignCrew={() => {
                            if (!showUnassignCrew) return;
                            handleUnassignCrew(item);
                          }}
                          isSavingUnassignment={isSavingUnassignment}
                        />
                      ))}
                  </div>
                </>
              )}
              {!isNullOrUndefined(selectedTurnaround?.originalProfile) && (
                <div className="turnaround-crew-list">
                  <div className="turnaround-crew-list-actions">
                    <button className="alternate" onClick={onAddCrewMember}>
                      {t("add_crew_member")}
                    </button>
                  </div>
                </div>
              )}
              {(hasGseRecords || hasMissingVehicleRequirements) && (
                <>
                  <div className="turnaround-vehicles">
                    <div>
                      <div className="context-panel-section-header">
                        {t("vehicles")}
                      </div>
                    </div>
                    {hasMissingVehicleRequirements &&
                      missingVehicleRequirements.map((missingVehicle) => (
                        <TurnaroundVehicleSlot
                          key={`${missingVehicle?.id}`}
                          vehicleInfo={missingVehicle}
                          selected={selectedVehicleUuid === missingVehicle?.id}
                          showActions={showActions}
                          onClick={() => {
                            setOperationPanelMode(
                              TurnaroundOperationPanelMode.LOCATOR
                            );
                            setLocateGseRequirement(missingVehicle);
                            resetScroll();
                            onSelectVehicle(null);
                          }}
                          isSelectable={showActions}
                        />
                      ))}
                    {hasGseRecords &&
                      gseRecordsToRender.map((item) => (
                        <TurnaroundVehicle
                          key={item.groundVehicle.uuid}
                          vehicleInfo={item}
                          vehiclePosition={getPositionForGse(
                            vehicles,
                            positions,
                            item.groundVehicle.uuid
                          )}
                          airportTimezone={airportTimezone}
                          selected={
                            selectedVehicleUuid === item.groundVehicle?.uuid
                          }
                          isSelectable={!isNullOrUndefined(onSelectVehicle)}
                          onClick={() => {
                            dispatch({
                              type: "setItemsToWhitelist",
                              value: null,
                            });
                            if (
                              !isNullOrUndefined(onSelectVehicle) &&
                              !isNullOrUndefined(item.groundVehicle?.uuid)
                            ) {
                              onSelectVehicle(item.groundVehicle?.uuid);
                            }
                          }}
                        />
                      ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

function getPositionForGse(vehicles, positions, gseUuid) {
  if (
    isNullOrUndefined(vehicles) ||
    isNullOrUndefined(positions?.groundVehicles) ||
    isNullOrUndefined(gseUuid)
  )
    return null;
  const vehicle = getByFieldValue(vehicles, "uuid", gseUuid);
  if (!isNullOrUndefined(vehicle?.trackerCode)) {
    return getByFieldValue(
      positions.groundVehicles,
      "trackerCode",
      vehicle.trackerCode
    );
  }
  return null;
}
export default TurnaroundOperationPanel;
