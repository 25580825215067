import { MAPINFO_USER_STATUS } from "./mapUtils";
import {
  getTurnaroundInfo,
  getTurnaroundInfoForTurnaroundSummary,
} from "./turnaroundUtils";
import { isEmptyList, isNullOrUndefined } from "./utils";
import i18next from "./i18n";
import { UserDispatchStatus } from "./constants";

export function getUserInfo(user, turnaroundsSummary, positions) {
  const userInfo = {
    uuid: user.uuid,
    user: user,
    fullName: `${user.firstName} ${user.lastName}`,
  };

  // TODO: Add support to include the active turnaround OPERATION for a user
  // Turnaround is available yet on the turnaroundsSummary via inProgressUsers
  const activeTurnaround = getActiveTurnaroundForUser(turnaroundsSummary, user);
  const activeTurnaroundInfo = !isNullOrUndefined(activeTurnaround)
    ? getTurnaroundInfoForTurnaroundSummary(activeTurnaround)
    : null;
  const userCertificationInfo = getUserCertificationInfo(user);
  const isOnMap = isUserOnMap(user, positions);
  userInfo.mapStatus = MAPINFO_USER_STATUS.NONE;
  if (isOnMap) {
    userInfo.mapStatus = !isNullOrUndefined(activeTurnaround)
      ? MAPINFO_USER_STATUS.IN_SERVICE
      : MAPINFO_USER_STATUS.AVAILABLE;
  }

  userInfo.details = !isNullOrUndefined(activeTurnaround)
    ? activeTurnaround.combinedFlightName
    : userInfo.certNames;

  userInfo.dispatchStatus = getDispatchStatus(userInfo);

  return {
    ...userInfo,
    ...userCertificationInfo,
    activeTurnaroundInfo: activeTurnaroundInfo,
  };
}

// TODO: Refactor to use the turnaroundsSummary (check in-progress turnaround)
export function getActiveTurnaroundOperationForUser(turnarounds, user) {
  if (!isNullOrUndefined(turnarounds)) {
    for (let i = 0; i < turnarounds.length; i++) {
      const turnaround = turnarounds[i];
      const turnaroundOperations = turnaround?.monitor?.turnaroundOperations;
      if (!isNullOrUndefined(turnaroundOperations)) {
        for (let j = 0; j < turnaroundOperations.length; j++) {
          const turnaroundOperation = turnaroundOperations[j];
          if (
            turnaroundOperation.enabled &&
            hasActiveCrewAssignmentsForUser(turnaroundOperation, user)
          ) {
            return {
              activeTurnaround: getTurnaroundInfo(turnaround),
              activeOperation: turnaroundOperation,
            };
          }
        }
      }
    }
  }
  return null;
}

// Get the active turnaround as specified by the turnaround summary inProgressUsers
export function getActiveTurnaroundForUser(turnarounds, user) {
  if (isEmptyList(turnarounds) || isNullOrUndefined(user)) return false;
  for (let i = 0; i < turnarounds.length; i++) {
    const turnaround = turnarounds[i];
    if (turnaround?.inProgressUsers.includes(user.uuid)) {
      return turnaround;
    }
  }
  return null;
}

function hasActiveCrewAssignmentsForUser(turnaroundOperation, user) {
  const turnaroundRequirements = turnaroundOperation.turnaroundRequirements;
  if (!isNullOrUndefined(turnaroundRequirements)) {
    for (let i = 0; i < turnaroundRequirements.length; i++) {
      const turnaroundRequirement = turnaroundRequirements[i];
      const crewAssignments = turnaroundRequirement.crewAssignments;
      const unassignedUserRecords = turnaroundRequirement.unassignedUserRecords;
      if (!isNullOrUndefined(crewAssignments)) {
        for (let j = 0; j < crewAssignments.length; j++) {
          const crewAssignment = crewAssignments[j];
          if (
            crewAssignment?.userUuid === user?.uuid &&
            !isNullOrUndefined(crewAssignment.startTime) &&
            isNullOrUndefined(crewAssignment.endTime)
          ) {
            return true;
          }
        }
      }
      if (!isNullOrUndefined(unassignedUserRecords)) {
        if (
          unassignedUserRecords?.user?.uuid === user?.uuid &&
          !isNullOrUndefined(unassignedUserRecords.startTime) &&
          isNullOrUndefined(unassignedUserRecords.endTime)
        ) {
          return true;
        }
      }
    }
  }
  return false;
}

export function getUserCertificationInfo(user) {
  const certInfo = {
    certNames: "",
    certList: [],
  };
  if (!isNullOrUndefined(user.certifications)) {
    certInfo.certList = user.certifications.map((i) => i.name).sort();
    if (certInfo.certList.length > 2) {
      certInfo.certNames = `${certInfo.certList[0]} (+${
        certInfo.certList.length - 1
      } ${i18next.t("more").toLowerCase()})`;
    } else {
      certInfo.certNames = certInfo.certList.join(", ");
    }
  }
  return certInfo;
}

export function isUserOnMap(user, positions) {
  const idx = positions?.users?.findIndex((item) => item.uuid === user?.uuid);
  return idx > -1;
}

// TODO: Need to get shift/schedule info to determine future status
function getDispatchStatus(userInfo) {
  const mapStatus = userInfo.mapStatus;
  if (mapStatus === MAPINFO_USER_STATUS.AVAILABLE) {
    return UserDispatchStatus.AVAILABLE;
  } else if (mapStatus === MAPINFO_USER_STATUS.IN_SERVICE) {
    return UserDispatchStatus.UNAVAILABLE_IN_SERVICE;
  } else if (mapStatus === MAPINFO_USER_STATUS.NONE) {
    return UserDispatchStatus.UNAVAILABLE_NOT_ONLINE;
  }
}
