import "./styles.css";
import moment from "moment-timezone";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import {
  filterBySearchQuery,
  formatTime,
  getMinMaxTimeValue,
  isBlank,
  isNullOrUndefined,
} from "../../utils";
import { useEffect, useState } from "react";
import { getTurnaroundInfoListForDay } from "../../turnaroundUtils";
import TurnaroundTimelineBar from "./TurnaroundTimelineBar";
import { ReactComponent as Caret } from "../../assets/caret-down.svg";
import { getTurnaroundsWithCriteria, getUsers } from "../../api";
import ButtonDatePicker from "../ButtonDatePicker";
import TurnaroundInfoPanel from "./TurnaroundInfoPanel";
import TurnaroundOperationPanel from "./TurnaroundOperationPanel";
import {
  DEFAULT_TIMEZONE,
  MIN_DATE,
  POLLING_INTERVALS,
  SEARCH_QUERY_PLACEHOLDERS,
} from "../../constants";
import { useTranslation } from "react-i18next";

// Hard coded value to adjust for the panel width
const CONTEXT_PANEL_WIDTH = 300;

function Turnarounds() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    turnaroundsWithCriteria,
    currentUser,
    turnaroundsWithCriteriaLoading,
    searchQuery,
    users: usersList,
  } = mainContext;
  const [selectedTurnaround, setSelectedTurnaround] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [panelOpen, setPanelOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [timelineContainerWidth, setTimelineContainerWidth] = useState(1200);

  const airport = !isNullOrUndefined(currentUser) ? currentUser.airport : null;
  const airportName = !isNullOrUndefined(airport) ? airport.iata : "";
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;
  const airportLocalTime = moment().tz(airportTimezone).startOf("minute");
  const mToday = moment().tz(airportTimezone).startOf("day");
  const [selectedDate, setSelectedDate] = useState(mToday);
  const mTomorrow = moment().tz(airportTimezone).startOf("day").add(1, "day");
  const mSelectedDate = moment(selectedDate).tz(airportTimezone).startOf("day");
  const mSelectedNextDate = moment(mSelectedDate)
    .tz(airportTimezone)
    .add(1, "day");
  const mSelectedPrevDate = moment(mSelectedDate)
    .tz(airportTimezone)
    .add(-1, "day");
  const mMinDate = moment(MIN_DATE).tz(airportTimezone).startOf("day");
  const isTodaySelected = mToday.isSame(mSelectedDate);
  const isTomorrowSelected = mTomorrow.isSame(mSelectedDate);
  const isMinDateSelected = mMinDate.isSame(mSelectedDate);

  useEffect(() => {
    getUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // convert to UTC
    const mEndTime = moment(selectedDate).tz(airportTimezone).add(1, "day");
    const criteria = {
      startTime: moment.utc(selectedDate).format(),
      endTime: moment.utc(mEndTime).format(),
    };
    getTurnaroundsWithCriteria(dispatch, criteria);
  }, [dispatch, selectedDate, airportTimezone]);

  useEffect(() => {
    dispatch({
      type: "setSearchQuery",
      value: {
        searchTerm: "",
        searchPlaceholder: SEARCH_QUERY_PLACEHOLDERS.SEARCH_TURNAROUNDS,
      },
    });
  }, [dispatch]);

  const turnaroundInfoList = getTurnaroundInfoListForDay(
    turnaroundsWithCriteria,
    airportTimezone,
    mSelectedDate,
    mSelectedNextDate,
    selectedTurnaround
  );
  const turnaroundsSorted = !isBlank(searchQuery?.searchTerm)
    ? filterBySearchQuery(
        searchQuery.searchTerm,
        turnaroundInfoList,
        "combinedFlightName"
      )
    : turnaroundInfoList;

  let selectedTurnaroundInfo = null;
  const mStartOfDay = moment(selectedDate).tz(airportTimezone).startOf("day");
  const mEndOfDay = moment(selectedDate)
    .tz(airportTimezone)
    .add(1, "day")
    .startOf("day");

  let timelineMetadata = {
    pixelToMinuteConversion: (2 * 60) / 100,
    minTime: moment(mStartOfDay),
    maxTime: moment(mEndOfDay),
    hoursPerSplit: 2,
    pixelsPerSplit: 100,
    pixelsPerMinute: 100 / (60 * 2),
  };

  if (!isNullOrUndefined(turnaroundsSorted) && turnaroundsSorted.length > 0) {
    for (let i = 0; i < turnaroundsSorted.length; i++) {
      const turnaroundInfo = turnaroundsSorted[i];

      // Find the earliest/latest times for the turnaround
      const timesToCheck = [
        !isNullOrUndefined(turnaroundInfo.gateIn)
          ? new Date(turnaroundInfo.gateIn)
          : null,
        turnaroundInfo.gtatStart,
        turnaroundInfo.gtatExpectedStartTime,
        !isNullOrUndefined(turnaroundInfo.gateOut)
          ? new Date(turnaroundInfo.gateOut)
          : null,
        turnaroundInfo.gtatEnd,
      ];
      const [mMinTime, mMaxTime] = getMinMaxTimeValue(
        timesToCheck,
        airportTimezone
      );
      if (turnaroundInfo.uuid === selectedTurnaround) {
        selectedTurnaroundInfo = turnaroundInfo;
        timelineMetadata.minTime = mMinTime;
        timelineMetadata.maxTime = mMaxTime;
      } else {
        if (mMinTime.isBefore(timelineMetadata.minTime)) {
          timelineMetadata.minTime = mMinTime;
        }
        if (mMaxTime.isAfter(timelineMetadata.maxTime)) {
          timelineMetadata.maxTime = mMaxTime;
        }
      }
    }

    const hasSelectedTurnaround = !isNullOrUndefined(selectedTurnaroundInfo);
    // Add padding if needed
    if (
      hasSelectedTurnaround ||
      timelineMetadata.minTime.isBefore(mStartOfDay)
    ) {
      timelineMetadata.minTime.startOf("hour").add(-1, "hour");
    }
    if (timelineMetadata.maxTime.isAfter(mEndOfDay) || hasSelectedTurnaround) {
      timelineMetadata.maxTime.endOf("hour").add(1, "hour");
    }
    timelineMetadata.timelineDurationMinutes = parseInt(
      moment
        .duration(timelineMetadata.maxTime.diff(timelineMetadata.minTime))
        .as("minutes")
    );

    // Increase the scale since we have less than 24 hours
    const isZoomedIn = timelineMetadata.timelineDurationMinutes < 24 * 60;
    if (isZoomedIn) {
      timelineMetadata.pixelsPerMinute =
        timelineContainerWidth / timelineMetadata.timelineDurationMinutes;
      timelineMetadata.hoursPerSplit = 1;
      timelineMetadata.pixelsPerSplit =
        timelineMetadata.pixelsPerMinute * timelineMetadata.hoursPerSplit * 60;
    }
  }

  // Construct the timeline markers
  const timelineHours = [];
  const mDate = moment(timelineMetadata.minTime);
  while (mDate.isBefore(timelineMetadata.maxTime)) {
    timelineHours.push(
      formatTime(mDate.toDate(), airportTimezone, true, selectedDate)
    );
    mDate.add(timelineMetadata.hoursPerSplit, "hour");
  }
  // Adjust width according to container as needed
  const calculatedWidth =
    timelineHours.length * timelineMetadata.pixelsPerSplit;
  const containerEl = document.querySelector(".turnarounds-timeline-container");
  if (!isNullOrUndefined(containerEl)) {
    const availableWidth = containerEl.getBoundingClientRect().width;
    if (calculatedWidth < availableWidth) {
      timelineMetadata.pixelsPerMinute =
        availableWidth / timelineMetadata.timelineDurationMinutes;
      timelineMetadata.pixelsPerSplit =
        timelineMetadata.pixelsPerMinute * timelineMetadata.hoursPerSplit * 60;
    }
  }
  const gridStyle = {
    gridTemplateColumns: `repeat(${timelineHours.length}, ${timelineMetadata.pixelsPerSplit}px)`,
  };

  const contentStyle = {
    gridTemplateRows: `repeat(${turnaroundsSorted.length}, auto) 1fr`,
  };

  const positionCurrentTimeMarker = () => {
    if (!isTodaySelected) return;
  };

  const refreshPageData = () => {
    if (!isTodaySelected) return;
    if (turnaroundsWithCriteriaLoading) return;
    // convert to UTC
    const mEndTime = moment(selectedDate).tz(airportTimezone).add(1, "day");
    const criteria = {
      startTime: moment.utc(selectedDate).format(),
      endTime: moment.utc(mEndTime).format(),
    };
    getTurnaroundsWithCriteria(dispatch, criteria);
  };

  useEffect(() => {
    const interval = setInterval(positionCurrentTimeMarker, 200);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (POLLING_INTERVALS.TURNAROUNDS < 0) return () => {};
    const interval = setInterval(
      refreshPageData,
      POLLING_INTERVALS.TURNAROUNDS
    );
    return () => clearInterval(interval);
  });

  /** Click handlers **/
  const handleChangeDate = (newValue) => {
    setDetailsOpen(false);
    setPanelOpen(false);
    setSelectedTurnaround(null);
    setSelectedOperation(null);
    setSelectedDate(newValue);
  };

  const handleTurnaroundSelect = (turnaroundUuid) => {
    setSelectedTurnaround(turnaroundUuid);
    setSelectedOperation(null);
    setDetailsOpen(false);
    setTimeout(() => {
      setPanelOpen(true);
    }, 100);
    const containerEl = document.querySelector(
      ".turnarounds-timeline-container"
    );
    if (!isNullOrUndefined(containerEl)) {
      setTimelineContainerWidth(
        containerEl.getBoundingClientRect().width - CONTEXT_PANEL_WIDTH
      );
    }
  };

  const handleOperationSelect = (value) => {
    if (!isNullOrUndefined(value)) {
      setDetailsOpen(true);
    } else {
      setDetailsOpen(false);
    }
    setSelectedOperation(value);
  };

  const handleInfoPanelClose = () => {
    setDetailsOpen(false);
    setPanelOpen(false);
    setTimeout(() => {
      setSelectedTurnaround(null);
      setSelectedOperation(null);
    }, 500);
  };

  const handleOperationPanelClose = () => {
    setDetailsOpen(false);
    setSelectedOperation(null);
  };

  return (
    <div className="turnarounds">
      <div className="turnarounds-container">
        <div className="turnarounds-timeline-toolbar">
          <div>
            <div className="date-navigation">
              <div>
                <button
                  disabled={isMinDateSelected}
                  className="nav-left"
                  onClick={() => {
                    handleChangeDate(mSelectedPrevDate);
                  }}
                >
                  <Caret />
                </button>
              </div>
              <div className="date-title">
                <h3 title={`${airportName} (${airportTimezone})`}>
                  {mSelectedDate.format("dddd, DD MMM yyyy")}
                </h3>
              </div>
              <div>
                <button
                  disabled={isTomorrowSelected}
                  className="nav-right"
                  onClick={() => {
                    handleChangeDate(mSelectedNextDate);
                  }}
                >
                  <Caret />
                </button>
              </div>
            </div>
          </div>
          <div className="actions">
            <ButtonDatePicker
              label={
                selectedDate == null ? null : selectedDate.format("MM/DD/YYYY")
              }
              value={selectedDate}
              onChange={(newValue) => {
                handleChangeDate(newValue);
              }}
              minDate={mMinDate}
              maxDate={moment()}
            />
          </div>
        </div>
        <div
          className={`panels-container${
            !isNullOrUndefined(selectedTurnaroundInfo) ? " open" : ""
          }${panelOpen ? " slide-open" : " slide-close"}${
            detailsOpen ? " open-details" : ""
          }`}
        >
          {!isNullOrUndefined(selectedTurnaroundInfo) && (
            <div className="context-panel">
              {!isNullOrUndefined(selectedTurnaroundInfo) && (
                <TurnaroundInfoPanel
                  selectedDate={selectedDate}
                  selectedTurnaroundInfo={selectedTurnaroundInfo}
                  selectedOperation={selectedOperation}
                  onClose={() => {
                    handleInfoPanelClose();
                  }}
                  panelOpen={panelOpen}
                  detailsClickHandler={(value) => {
                    handleOperationSelect(value);
                  }}
                />
              )}
            </div>
          )}
          {!isNullOrUndefined(selectedOperation) && detailsOpen && (
            <div className="context-panel">
              <TurnaroundOperationPanel
                selectedTurnaround={selectedTurnaroundInfo}
                selectedOperation={selectedOperation}
                onClose={() => {
                  handleOperationPanelClose();
                }}
                airportTimezone={airportTimezone}
                users={usersList}
                showActions={false}
              />
            </div>
          )}
          <div
            className="turnarounds-timeline-container"
            onScroll={(e) => {
              const timelineMarkerEl =
                document.querySelector(".timeline-marker");
              if (!isNullOrUndefined(timelineMarkerEl)) {
                timelineMarkerEl.style.height = `${e.target.scrollHeight}px`;
              }
            }}
          >
            <div className="turnarounds-timeline-header" style={gridStyle}>
              {timelineHours.map((hourVal, idx) => (
                <div key={idx} id={`hour-${hourVal.replaceAll(":", "-")}`}>
                  <span>{hourVal}</span>
                </div>
              ))}
            </div>
            <div className="turnarounds-timeline-body">
              <div
                className="turnarounds-timeline-content gtat"
                style={contentStyle}
              >
                {turnaroundsSorted.length > 0 &&
                  turnaroundsSorted.map((item, idx) => (
                    <TurnaroundTimelineBar
                      key={idx}
                      airport={airport}
                      item={item}
                      isSelected={item.uuid === selectedTurnaround}
                      selectedOperation={selectedOperation?.uuid}
                      handleClick={(turnaroundUuid, refEl) => {
                        handleTurnaroundSelect(turnaroundUuid, refEl);
                      }}
                      handleOperationClick={(value, refEl) => {
                        handleOperationSelect(value, refEl);
                      }}
                      handleCloseClick={() => {
                        handleInfoPanelClose();
                      }}
                      timelineMetadata={timelineMetadata}
                    />
                  ))}
              </div>
              <div className="turnarounds-timeline-grid" style={gridStyle}>
                {timelineHours.map((hourVal, idx) => (
                  <div key={`${hourVal.replaceAll(":", "-")}-${idx}`}></div>
                ))}
              </div>
            </div>
            {isTodaySelected &&
              airportLocalTime.isBetween(
                timelineMetadata.minTime,
                timelineMetadata.maxTime,
                null,
                "[]"
              ) && (
                <div
                  className="timeline-marker"
                  style={{
                    left: getTimeAsPixels(airportLocalTime, timelineMetadata),
                  }}
                >
                  <div
                    className="timeline-marker-badge"
                    title={`${airportLocalTime.format(
                      "HH:mm"
                    )} (${airportTimezone})`}
                  >
                    {t("now")}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

function getTimeAsPixels(mTimeVal, timelineMetadata) {
  const { pixelsPerMinute, minTime } = timelineMetadata;
  const pixels =
    parseInt(moment.duration(mTimeVal.diff(minTime)).as("minute")) *
    pixelsPerMinute;
  return pixels > 0 ? pixels : 0;
}

export default Turnarounds;
