import { useState } from "react";
import {
  deepCopy,
  getMapFromList,
  isBlank,
  isNullOrUndefined,
  sortByField,
} from "../../utils";
import Checkbox from "../Checkbox";
import TemplateOperation from "./TemplateOperation";
import TemplateTimeline from "./TemplateTimeline";
import { ReactComponent as LeftArrow } from "../../assets/arrow-left-sm.svg";
import { ReactComponent as RightArrow } from "../../assets/arrow-right-sm.svg";
import { ReactComponent as Check } from "../../assets/check-white.svg";
import { ReactComponent as Turnaround } from "../../assets/turnaround-large.svg";
import TemplateReview from "./TemplateReview";
import { useTranslation } from "react-i18next";
import { getEnabledTurnaroundProfileRequirements } from "../../turnaroundProfileUtils";
import TemplateDiagram from "./TemplateDiagram";
import { getGseTypeByCertificationName } from "../../gseUtils";
import { usePostHog } from "posthog-js/react";
import { FEATURE_FLAGS } from "../../constants";
import Tarmac from "../../assets/diagram/tarmac.jpg";

function TemplateEditor(props) {
  const { t } = useTranslation();
  const { template, onBack, onSave, isTemplateSaving, isReadOnly } = props;
  const [templateToEdit, setTemplateToEdit] = useState(deepCopy(template));
  const [editorMode, setEditorMode] = useState("configure"); // "review"
  const [hasChanges, setHasChanges] = useState(false);
  const operationsList = templateToEdit?.turnaroundOperations;
  const [targetBlockTime, setTargetBlockTime] = useState(120);
  const posthog = usePostHog();
  const isTemplateDiagramEnabled = posthog.isFeatureEnabled(
    FEATURE_FLAGS.TEMPLATE_DIAGRAM
  );

  sortByField(operationsList, "name");
  const defaultOperation =
    operationsList?.length > 0 ? operationsList?.[0] : null;
  const [selectedOperation, setSelectedOperation] = useState(defaultOperation);
  if (isNullOrUndefined(templateToEdit)) return null;

  // Templates don't have names (yet)
  const airline = template?.airline ? template?.airline.name : "-";
  const airport = template?.airport ? template?.airport.iata : "-";
  const aircraft = template?.aircraftType ? template?.aircraftType.name : "-";
  const templateName = `${aircraft} - ${airline} ${airport}`;

  const enabledTurnaroundProfileRequirements =
    getEnabledTurnaroundProfileRequirements(templateToEdit);
  const enabledGSETypes = {};
  if (enabledTurnaroundProfileRequirements?.length > 0) {
    for (let i = 0; i < enabledTurnaroundProfileRequirements.length; i++) {
      const turnaroundRequirement = enabledTurnaroundProfileRequirements[i];
      const gseTypeUuid =
        turnaroundRequirement?.certification?.groundVehicleTypeUuid;
      if (!isNullOrUndefined(gseTypeUuid)) {
        const quantity = turnaroundRequirement.quantity;
        const parentOperation = turnaroundRequirement.parentOperation;
        const operationInfo = {
          operation: parentOperation,
          requirement: turnaroundRequirement,
          quantity: quantity,
        };

        if (!isNullOrUndefined(enabledGSETypes[gseTypeUuid])) {
          enabledGSETypes[gseTypeUuid].quantity =
            enabledGSETypes[gseTypeUuid].quantity + quantity;
          enabledGSETypes[gseTypeUuid].operationsInfo[parentOperation.uuid] =
            operationInfo;
        } else {
          const operationsInfo = {};
          operationsInfo[parentOperation.uuid] = operationInfo;
          const gseType = getGseTypeByCertificationName(
            turnaroundRequirement?.certification?.name
          );
          enabledGSETypes[gseTypeUuid] = {
            gseType: gseType,
            certification: turnaroundRequirement.certification,
            quantity: quantity,
            operationsInfo: operationsInfo,
          };
        }
      }
    }
  }
  function handleToggleOperation(operationUuid) {
    const templateToUpdate = deepCopy(templateToEdit);
    const operationsByUuid = getMapFromList(
      templateToUpdate.turnaroundOperations,
      "uuid"
    );
    const operationToEdit = operationsByUuid[operationUuid];
    if (!isNullOrUndefined(operationToEdit)) {
      operationToEdit.enabled = !operationToEdit.enabled;
    }
    setTemplateToEdit(templateToUpdate);
    setHasChanges(true);
  }

  function handleOperationUpdate(value) {
    const operationToUpdate = deepCopy(value);
    const templateToUpdate = deepCopy(templateToEdit);
    const turnaroundOperations = [];
    for (let i = 0; i < templateToUpdate.turnaroundOperations.length; i++) {
      const turnaroundOperation = templateToUpdate.turnaroundOperations[i];
      if (turnaroundOperation.uuid === value.uuid) {
        turnaroundOperations.push(operationToUpdate);
      } else {
        turnaroundOperations.push(turnaroundOperation);
      }
    }
    templateToUpdate.turnaroundOperations = turnaroundOperations;

    // Make sure to also update the selected operation
    if (!isNullOrUndefined(selectedOperation)) {
      setSelectedOperation(operationToUpdate);
    }
    setTemplateToEdit(templateToUpdate);
    setHasChanges(true);
  }

  const isConfigureMode = editorMode === "configure";
  const isReviewMode = editorMode === "review";
  const isNextButtonDisabled = !isReadOnly && (isTemplateSaving || !hasChanges);
  return (
    <div className="template-editor">
      <div>
        <div className="template-editor-toolbar">
          <div>
            <div>
              <button
                className="secondary"
                onClick={() => {
                  if (isConfigureMode) {
                    onBack();
                  }
                  if (isReviewMode) {
                    setEditorMode("configure");
                  }
                }}
                disabled={isTemplateSaving}
              >
                <LeftArrow />
                {t("back")}
              </button>
            </div>
            <div>
              {isConfigureMode && !isReadOnly && (
                <h2>{`${t("edit")}: ${templateName}`}</h2>
              )}
              {isConfigureMode && isReadOnly && (
                <h2>{`${t("view")}: ${templateName}`}</h2>
              )}
              {isReviewMode && <h2>Review turnaround template</h2>}
            </div>
            <div>
              {isConfigureMode && (
                <button
                  className="primary"
                  onClick={() => {
                    setEditorMode("review");
                  }}
                  disabled={isNextButtonDisabled}
                >
                  <RightArrow /> {t("next")}
                </button>
              )}
              {isReviewMode && (
                <div>
                  <button
                    className="primary"
                    onClick={() => {
                      onSave(templateToEdit);
                    }}
                    disabled={isReadOnly || isTemplateSaving}
                  >
                    <Check /> {t("save")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {isConfigureMode && (
          <>
            <div className="template-editor-content">
              <div className="template-editor-side-panel">
                <div className="module">
                  <div>
                    <div className="side-panel-header">
                      <h3>Operations</h3>
                    </div>
                    {operationsList &&
                      operationsList.map((operation) => (
                        <div
                          className={`side-panel-item${
                            operation.uuid === selectedOperation.uuid
                              ? " selected"
                              : ""
                          }`}
                          key={operation.uuid}
                          onClick={() => {
                            setSelectedOperation(operation);
                          }}
                        >
                          <div>
                            <div>
                              <label>{operation.name}</label>
                            </div>
                            <div>
                              <Checkbox
                                isChecked={operation.enabled}
                                changeHandler={() => {
                                  handleToggleOperation(operation.uuid);
                                }}
                                isDisabled={isReadOnly}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="template-editor-main-panel">
                <>
                  {isTemplateDiagramEnabled && (
                    <div
                      className="module diagram"
                      style={{ backgroundImage: `url(${Tarmac})` }}
                    >
                      <TemplateDiagram
                        aircraftName={aircraft}
                        enabledGSETypes={enabledGSETypes}
                        selectedOperation={selectedOperation}
                      />
                    </div>
                  )}
                  <TemplateOperation
                    selectedOperation={selectedOperation}
                    onChange={(value) => {
                      handleOperationUpdate(value);
                    }}
                    isReadOnly={isReadOnly}
                  />
                  <div className="module">
                    <div className="timeline-module">
                      <div>
                        <div className="timeline-header">
                          <div>
                            <h3>{t("timeline")}</h3>
                          </div>
                          <div>
                            <div className="timeline-header-fields">
                              <div>
                                <label>{t("target_block_time")}:</label>
                              </div>
                              <div>
                                <input
                                  type="text"
                                  placeholder={t("enter_duration")}
                                  value={targetBlockTime}
                                  onChange={(e) => {
                                    setTargetBlockTime(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    const currVal = !isNaN(
                                      parseInt(e.target.value)
                                    )
                                      ? parseInt(e.target.value)
                                      : 0;
                                    if (e.code === "ArrowUp") {
                                      setTargetBlockTime(currVal + 5);
                                    } else if (e.code === "ArrowDown") {
                                      if (currVal - 5 < 0) {
                                        setTargetBlockTime(0);
                                      } else {
                                        setTargetBlockTime(currVal - 5);
                                      }
                                    }
                                    if (e.key.length === 1) {
                                      const keyVal = parseInt(e.key);
                                      if (isNaN(keyVal)) {
                                        e.preventDefault();
                                      }
                                    }
                                  }}
                                />
                              </div>
                              <div>
                                <label>{t("minutes").toLowerCase()}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="timeline-body">
                          <TemplateTimeline
                            template={templateToEdit}
                            targetBlockTime={targetBlockTime}
                            enableSelect={true}
                            onSelect={(operation) => {
                              setSelectedOperation(operation);
                            }}
                            selectedOperationUuid={selectedOperation.uuid}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </>
        )}
        {isReviewMode && (
          <>
            <div className="module">
              <div className="turnaround-review-module">
                <div>
                  <Turnaround />
                </div>
                <div>
                  <h3>{templateName}</h3>
                </div>
                <div>
                  <div className="label-value-pair">
                    <div>
                      <label>{t("status")}</label>
                    </div>
                    <div>
                      <div className="badge published">
                        <div>{t("published")}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="label-value-pair">
                  <div>
                    <label>{t("airport")}</label>
                  </div>
                  <div>{airport}</div>
                </div>
                <div className="label-value-pair">
                  <div>
                    <label>{t("aircraft")}</label>
                  </div>
                  <div>{aircraft}</div>
                </div>
              </div>
            </div>

            <div>
              <TemplateReview template={templateToEdit} />
            </div>

            <div className="module">
              <div className="timeline-module">
                <div>
                  <div className="timeline-header">
                    <div>
                      <h3>{t("timeline")}</h3>
                    </div>
                    <div className="timeline-header-info">
                      {!isBlank(targetBlockTime) && (
                        <>{`${t("target_block_time")}: ${targetBlockTime} ${t(
                          "minutes"
                        ).toLowerCase()}`}</>
                      )}
                    </div>
                  </div>
                  <div className="timeline-body">
                    <TemplateTimeline
                      template={templateToEdit}
                      onSelect={(operation) => {
                        setSelectedOperation(operation);
                      }}
                      enableSelect={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TemplateEditor;
