import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { ReactComponent as LeftArrow } from "../../assets/arrow-left-sm.svg";
import { useTranslation } from "react-i18next";
import TurnaroundCrew from "./TurnaroundCrew";
import {
  getByFieldValue,
  isEmptyList,
  isNullOrUndefined,
  scrollElementIntoView,
  sortByField,
} from "../../utils";
import { useMainDispatchContext } from "../../MainContext";
import { getUserInfoForMap } from "../../mapUtils";
import { MAP_RIGHT_PANEL_MODES } from "../../constants";
import { useEffect, useState } from "react";
import { isUserOnMap } from "../../userUtils";

function UnassignedCrewPanel(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const [crewListToRender, setCrewListToRender] = useState([]);
  const [scrollToUserUuid, setScrollToUserUuid] = useState(null);
  const {
    onBack,
    onClose,
    selectedTurnaround,
    selectedUserUuid,
    onSelectUser,
    showActions,
    positions,
    turnarounds,
    airportTimezone,
  } = props;

  // Handle scrolling to the selected tile
  useEffect(() => {
    if (
      !isNullOrUndefined(selectedUserUuid) &&
      scrollToUserUuid !== selectedUserUuid
    ) {
      const containerEl = document.querySelector(".overlay-panel-content");
      const targetEl = document.querySelector(
        `[data-uuid='${selectedUserUuid}'`
      );
      if (!isNullOrUndefined(targetEl)) {
        scrollElementIntoView(containerEl, targetEl);
        setScrollToUserUuid(selectedUserUuid);
      }
    }
  }, [selectedUserUuid, crewListToRender, scrollToUserUuid]);

  useEffect(() => {
    const userRecordsToCheck =
      selectedTurnaround?.originalMonitor?.unassignedUserRecords;
    const userInfoList = [];
    const crewToRenderList = [];

    if (!isEmptyList(userRecordsToCheck)) {
      for (let i = 0; i < userRecordsToCheck.length; i++) {
        const userRecordToCheck = userRecordsToCheck[i];
        const userUuid = userRecordToCheck.user.uuid;
        const userPosition = getByFieldValue(
          positions?.users,
          "uuid",
          userUuid
        );
        if (!isNullOrUndefined(userRecordToCheck.user)) {
          const userInfo = getUserInfoForMap(
            userRecordToCheck.user,
            userPosition,
            turnarounds
          );
          const user = userRecordToCheck.user;
          const crewAssignent = {
            uuid: user.uuid,
            fullName: `${user.firstName} ${user.lastName}`,
            user: user,
            userRecord: userRecordToCheck,
          };
          userInfoList.push(userInfo);
          crewToRenderList.push(crewAssignent);
        }
      }
    }
    sortByField(crewToRenderList, "fullName");
    setCrewListToRender(crewToRenderList);
    dispatch({
      type: "setItemsToWhitelist",
      value: userInfoList,
    });
  }, [dispatch, selectedTurnaround, turnarounds, positions]);

  return (
    <div className="unassigned-user-panel">
      <div className="panel-module">
        <div className="context-panel-content-header">
          <div className="back-btn">
            <div>
              <button
                className="outline-filled"
                onClick={() => {
                  dispatch({
                    type: "setItemsToWhitelist",
                    value: null,
                  });
                  onBack();
                }}
              >
                <LeftArrow /> {t("back")}
              </button>
            </div>
          </div>
          <div className="close-btn" onClick={onClose}>
            <CloseBtn />
          </div>
        </div>
        <div className="turnaround-content open">
          <div className="unassigned-user-panel-summary">
            <div>{t("unassigned_crew_text")}</div>
          </div>
          <div className="turnaround-crew-list">
            {!isEmptyList(crewListToRender) &&
              crewListToRender.map((item) => (
                <TurnaroundCrew
                  key={item.uuid}
                  crewInfo={item}
                  airportTimezone={airportTimezone}
                  selected={selectedUserUuid === item.uuid}
                  showCertifications={true}
                  showActions={showActions}
                  showAssignCrew={true}
                  onAssignCrew={() => {
                    if (!showActions) return;
                    if (
                      !isNullOrUndefined(onSelectUser) &&
                      !isNullOrUndefined(item.uuid)
                    ) {
                      onSelectUser(
                        item.uuid,
                        MAP_RIGHT_PANEL_MODES.CREW_ASSIGNMENTS
                      );
                    }
                  }}
                  isSelectable={
                    selectedUserUuid !== item.uuid &&
                    !isNullOrUndefined(onSelectUser) &&
                    isUserOnMap(item?.user, positions)
                  }
                  onClick={() => {
                    if (!showActions) return;
                    if (
                      !isNullOrUndefined(onSelectUser) &&
                      !isNullOrUndefined(item.uuid)
                    ) {
                      onSelectUser(item.uuid, null);
                    }
                  }}
                  isUnassigned={true}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnassignedCrewPanel;
