import "./styles.css";
import { getByFieldValue, isEmptyList, isNullOrUndefined } from "../../utils";
import { ReactComponent as CloseBtn } from "../../assets/close-btn.svg";
import { useTranslation } from "react-i18next";
import SelectCrewMember from "../Dispatches/SelectCrewMember";
import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { useEffect, useState } from "react";
import {
  getTurnaroundDetails,
  getUsers,
  patchTurnaroundProfile,
} from "../../api";
import { selectInputFix } from "../../styleUtils";

function AllocationsModule(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const { positions, users, turnarounds } = mainContext;

  const {
    selectedTurnaroundInfo,
    selectedOperation,
    selectedUserUuid,
    onClose,
    onAssignedUser,
  } = props;

  const [isSavingAssignment, setIsSavingAssignment] = useState(false);
  const [selectedRequirement, setSelectedRequirement] = useState(null);

  useEffect(() => {
    if (!isNullOrUndefined(selectedUserUuid)) {
      getUsers(dispatch);
    }
  }, [dispatch, selectedUserUuid]);

  useEffect(() => {
    if (isNullOrUndefined(selectedOperation)) {
      setSelectedRequirement(null);
    }
  }, [selectedOperation]);

  useEffect(() => {
    if (!isNullOrUndefined(selectedUserUuid)) {
      // The selectedUserUuid is not really a userUuid since this is a slot that needs to be filled
      // It is a composite of the requirement UUID and an index
      const originalProfile = selectedTurnaroundInfo?.originalProfile;
      const operationToCheck = !isNullOrUndefined(originalProfile)
        ? getByFieldValue(
            originalProfile.turnaroundOperations,
            "uuid",
            selectedOperation?.uuid
          )
        : null;
      const requirementUuid = selectedUserUuid?.split("_")[0];
      const requirement =
        !isNullOrUndefined(operationToCheck?.turnaroundRequirements) &&
        !isNullOrUndefined(requirementUuid)
          ? getByFieldValue(
              operationToCheck.turnaroundRequirements,
              "uuid",
              requirementUuid
            )
          : null;
      setSelectedRequirement(requirement);
    }
  }, [dispatch, selectedUserUuid, selectedOperation, selectedTurnaroundInfo]);

  // If there is no requirement provided, then user needs to select from the UI
  const requirementName = selectedRequirement?.certification?.name;

  const operationRequirements = selectedOperation?.turnaroundRequirements;
  const originalProfile = selectedTurnaroundInfo?.originalProfile;

  async function handleAssignUser(user) {
    if (
      isNullOrUndefined(user) ||
      isNullOrUndefined(selectedTurnaroundInfo?.originalProfile)
    )
      return;
    setIsSavingAssignment(true);
    const currentQuantity = selectedRequirement.quantity;
    const currentAssignments = !isEmptyList(
      selectedRequirement?.crewAssignments
    )
      ? selectedRequirement?.crewAssignments?.length
      : 0;
    // Increment the quantity if needed, otherwise fill available quantity slots
    const adjustedQuantity = Math.max(currentAssignments + 1, currentQuantity);
    const patchRequest = {
      uuid: originalProfile.uuid,
      operations: [],
      requirements: [
        {
          uuid: selectedRequirement.uuid,
          quantity: adjustedQuantity,
          userUuidsToAdd: [user.uuid],
        },
      ],
      parentLabelUuidsToApply: [],
      labelUuidsToRemove: [],
    };
    const result = await patchTurnaroundProfile(dispatch, patchRequest);
    await getTurnaroundDetails(dispatch, selectedTurnaroundInfo);
    if (result) {
      if (!isNullOrUndefined(onAssignedUser)) {
        // Function to call when the user has been assigned
        onAssignedUser();
      }
      let dispatchMessage = `${t("saved_web", {
        name: selectedTurnaroundInfo.combinedFlightName,
      })}`;
      dispatch({
        type: "setAlertMessage",
        alertMessage: dispatchMessage,
      });
    }
    onClose();
    setSelectedRequirement(null);
    setIsSavingAssignment(false);
  }

  const hasSelectedRequirement = !isNullOrUndefined(selectedRequirement);
  const title = hasSelectedRequirement
    ? `${t("assign")} ${requirementName}`
    : `${t("add_crew_member")}`;

  function handleSelectRequirement(e) {
    const requirementUuid = e.target.value;
    const requirement = getByFieldValue(
      operationRequirements,
      "uuid",
      requirementUuid
    );
    if (!isNullOrUndefined(requirement)) {
      setSelectedRequirement(requirement);
    }
  }

  return (
    <div className="allocations-module">
      <div className="allocations-module-header">
        <div>
          <div className="allocations-module-header-title">{title}</div>
          <div className="allocations-module-header-actions">
            <div
              className="close-btn"
              onClick={() => {
                setSelectedRequirement(null);
                onClose();
              }}
            >
              <CloseBtn />
            </div>
          </div>
        </div>
      </div>
      <div className="allocations-module-body">
        <div className="allocations-module-content">
          {isNullOrUndefined(selectedRequirement) && (
            <div>
              <select
                style={selectInputFix}
                onChange={handleSelectRequirement}
                value={selectedRequirement?.uuid}
                className={`${!hasSelectedRequirement ? "not-selected" : ""}`}
              >
                <option value="">Select certification&hellip;</option>
                {operationRequirements &&
                  operationRequirements.map((requirement) => (
                    <option value={requirement.uuid} key={requirement.uuid}>
                      {requirement.certification.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
          {!isNullOrUndefined(selectedRequirement) && (
            <SelectCrewMember
              onSelect={(user) => {
                // Assign the user to this requirement
                handleAssignUser(user);
              }}
              onCancel={() => {
                // No-op
              }}
              certification={selectedRequirement?.certification}
              crewAssignments={selectedRequirement?.crewAssignments}
              users={users}
              positions={positions}
              turnarounds={turnarounds}
              disabled={isSavingAssignment}
              isAssignMode={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default AllocationsModule;
