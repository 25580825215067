import "./styles.css";
import { isNullOrUndefined } from "../../utils";
import { MAPINFO_USER_STATUS } from "../../mapUtils";
import UserPic, { UserPicSize, UserPicTheme } from "../UserBadge/UserPic";
import { useMainDispatchContext } from "../../MainContext";
import { CHAT_CATEGORY_TYPES } from "../../commsUtils";
import { useTranslation } from "react-i18next";

function CrewInfo(props) {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const {
    user,
    details,
    mapStatus,
    userPicSize,
    userPicTheme,
    hideInfo = false,
    showActions = false,
    showAssignCrew = false,
    onAssignCrew,
    showUnassignCrew = false,
    isSavingUnassignment = false,
    onUnassignCrew,
  } = props;

  const currentMapStatus = !isNullOrUndefined(mapStatus)
    ? mapStatus
    : MAPINFO_USER_STATUS.NONE;
  return (
    <div className={`crew-info${showActions ? " show-actions" : ""}`}>
      <div className="crew-info-pic">
        <UserPic
          size={userPicSize || UserPicSize.MEDIUM}
          theme={userPicTheme || UserPicTheme.DARK}
          user={user}
        />
        <div
          className={`crew-info-status status-${currentMapStatus} ${
            userPicTheme || UserPicTheme.DARK
          }`}
        ></div>
      </div>
      <div className="crew-info-label">
        {!hideInfo && (
          <>
            <div className="crew-info-name">{`${user.firstName} ${user.lastName}`}</div>
            <div className="crew-info-detail">{details}</div>
          </>
        )}
      </div>
      {showActions && (
        <div className="crew-info-actions">
          <div>
            <button
              className="outlined"
              onClick={(e) => {
                dispatch({
                  type: "setSelectedChatRequest",
                  value: {
                    type: CHAT_CATEGORY_TYPES.DIRECT,
                    userUuid: user.uuid,
                  },
                });
                e.stopPropagation();
              }}
            >
              {t("chat")}
            </button>
          </div>
          {showUnassignCrew && (
            <div>
              <button
                className="danger-outline"
                onClick={(e) => {
                  onUnassignCrew(user);
                  e.stopPropagation();
                }}
                disabled={isSavingUnassignment}
              >
                {t("unassign")}
              </button>
            </div>
          )}
          {showAssignCrew && (
            <div>
              <button
                className="outlined"
                onClick={(e) => {
                  onAssignCrew(user);
                  e.stopPropagation();
                }}
              >
                {t("resolve")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CrewInfo;
