import { ANALYTICS_EVENTS } from "./constants";
import { CookieManager } from "./cookies";
import { ResourceActions, userHasResourceAction } from "./resourceActionsUtils";
import { isBlank, isNullOrUndefined } from "./utils";
import posthog from "posthog-js";
import i18next from "./i18n";

// These special cases expect to have response data
const RESOURCES_WITH_RESPONSE_DATA = [
  "positions",
  "reports/gse-utilization",
  "reports/on-time-performance",
  "reports/user-leaderboard",
  "reports/gse-utilization/export",
  "reports/on-time-performance/export",
  "reports/user-leaderboard/export",
  "user/current-deployment-user-group",
  "comms/channels",
];
const CONTEXTS_WITH_RESPONSE_DATA = ["TurnaroundLabelsPreview"];
const RESOURCES_WITH_SILENT = ["positions"];

export function getEnvName() {
  const location = window.location.href;
  if (location.indexOf("https://halo.moonware.com") === 0) return "prod";
  if (
    location.indexOf("https://halo.staging.moonware.com") === 0 ||
    process.env.REACT_APP_API_URL.indexOf(
      "https://api.staging.moonware.com"
    ) === 0
  )
    return "staging";
  if (location.indexOf("https://halo.dev.moonware.com") === 0) return "dev";
  return "local";
}
export function getApiUrl(envName) {
  if (envName === "prod") return "https://api.moonware.com";
  if (envName === "local") return process.env.REACT_APP_API_URL;
  return `https://api.${envName}.moonware.com`;
}

export const REACT_APP_ENV_NAME = getEnvName();
const baseAPI = getApiUrl(REACT_APP_ENV_NAME);

const baseHeader = {
  "Content-Type": "application/json",
};

function getHeadersWithAuthToken() {
  //const accessToken = localStorage.getItem("accessToken");
  const accessToken = CookieManager.getCookieValue("accessToken");

  return {
    ...baseHeader,
    Authorization: accessToken,
  };
}

// Handle !response.ok
// Returns true if an error message was displayed
export function handleBadResponse(response, errors, dispatch) {
  if (response.status > 400 && response.status < 600) {
    // Log for debugging
    console.log(
      "DEBUG INFO:",
      JSON.stringify({
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      })
    );
    // No further action needed
    return false;
  }
  if (!isNullOrUndefined(errors) && errors.length > 0) {
    dispatch({
      type: "setGlobalError",
      globalError: errors[0],
    });
    return true;
  }
  return false;
}

export async function login(loginValues, dispatch) {
  const apiPath = `${baseAPI}/login`;
  try {
    const response = await fetch(apiPath, {
      method: "post",
      headers: baseHeader,
      body: JSON.stringify(loginValues),
    });
    const jsonData = await response.json();
    const { content, errors } = jsonData;
    if (!response.ok) {
      const errorsShown = handleBadResponse(response, errors, dispatch);
      if (!errorsShown) {
        dispatch({
          type: "setGlobalError",
          globalError: i18next.t("login_error_msg"),
        });
      }
    } else {
      const { accessToken, refreshToken, mediaSignatures } = content;
      // save these tokens for later use
      if (!isBlank(accessToken)) {
        CookieManager.setCookieValue("accessToken", accessToken);
        // localStorage.setItem("accessToken", accessToken);
      }
      if (!isBlank(refreshToken)) {
        CookieManager.setCookieValue("refreshToken", refreshToken);
        // localStorage.setItem("refreshToken", refreshToken);
      }
      if (!isNullOrUndefined(mediaSignatures)) {
        CookieManager.setCookieValue(
          "mediaSignatures",
          JSON.stringify(mediaSignatures)
        );
      }
      // fetch user
      getUser(dispatch);
    }
  } catch (e) {
    handleException(dispatch, e);
    dispatch({
      type: "setGlobalError",
      globalError: i18next.t("login_error_msg"),
    });
  }
}

export function logout() {
  localStorage.clear();
  try {
    posthog.reset();
  } catch (ignored) {
    console.log(ignored);
  }
  CookieManager.clearAllCookies();
  window.location.href = "/";
}

export async function getUser(dispatch) {
  const apiPath = `${baseAPI}/user`;
  try {
    const response = await fetch(apiPath, {
      headers: getHeadersWithAuthToken(),
    });
    const jsonData = await response.json();
    const { content, errors } = jsonData;
    if (!response.ok) {
      handleBadResponse(response, errors, dispatch);
    } else {
      // webapp permissions check
      if (
        !userHasResourceAction(content, ResourceActions.ViewWebApp) &&
        !content?.isInternal
      ) {
        dispatch({
          type: "setGlobalError",
          globalError: i18next.t("webapp_access_auth_msg"),
        });
        if (!(window.location.href.indexOf("/login") > -1)) {
          // If not on login page, logout
          setTimeout(() => {
            logout();
          }, 1000);
        }
        return;
      }

      // localStorage.setItem("email", content.email);
      CookieManager.setCookieValue("email", content.email);
      dispatch({
        type: "setCurrentUser",
        currentUser: content,
      });
    }
  } catch (e) {
    handleException(dispatch, e);
    dispatch({
      type: "setGlobalError",
      globalError: i18next.t("login_user_error_msg"),
    });
  }
}

export async function sendCode(loginValues, dispatch) {
  const apiPath = `${baseAPI}/forgot-password`;
  try {
    const response = await fetch(apiPath, {
      method: "post",
      headers: baseHeader,
      body: JSON.stringify(loginValues),
    });
    const jsonData = await response.json();
    const { errors } = jsonData;
    if (!response.ok) {
      handleBadResponse(response, errors, dispatch);
    } else {
      // just notifiy the user
      dispatch({
        type: "setCodeSent",
        codeSent: true,
      });
    }
  } catch (e) {
    handleException(dispatch, e);
    dispatch({
      type: "setGlobalError",
      globalError: i18next.t("send_code_error_msg"),
    });
  }
}

export async function resetPassword(loginValues, dispatch) {
  const apiPath = `${baseAPI}/confirm-forgot-password`;
  try {
    const response = await fetch(apiPath, {
      method: "post",
      headers: baseHeader,
      body: JSON.stringify(loginValues),
    });
    const jsonData = await response.json();
    const { errors } = jsonData;
    if (!response.ok) {
      handleBadResponse(response, errors, dispatch);
    } else {
      dispatch({
        type: "setPasswordReset",
        passwordReset: true,
      });
    }
  } catch (e) {
    handleException(dispatch, e);
    dispatch({
      type: "setGlobalError",
      globalError: i18next.t("reset_password_error_msg"),
    });
  }
}

export async function signUp(signupValues, dispatch) {
  const apiPath = `${baseAPI}/register`;
  try {
    const response = await fetch(apiPath, {
      method: "post",
      headers: baseHeader,
      body: JSON.stringify(signupValues),
    });
    const jsonData = await response.json();
    const { errors } = jsonData;
    if (!response.ok) {
      handleBadResponse(response, errors, dispatch);
    } else {
      dispatch({
        type: "setSignedUp",
        signedUp: true,
      });
    }
  } catch (e) {
    handleException(dispatch, e);
    dispatch({
      type: "setGlobalError",
      globalError: i18next.t("signup_error_msg"),
    });
  }
}

export async function verifySignUp(verifyValues, dispatch) {
  const apiPath = `${baseAPI}/verify-account`;
  try {
    const response = await fetch(apiPath, {
      method: "post",
      headers: baseHeader,
      body: JSON.stringify(verifyValues),
    });
    const jsonData = await response.json();
    const { errors } = jsonData;
    if (!response.ok) {
      handleBadResponse(response, errors, dispatch);
    } else {
      dispatch({
        type: "setVerifiedSignUp",
        verifiedSignUp: true,
      });
    }
  } catch (e) {
    handleException(dispatch, e);
    dispatch({
      type: "setGlobalError",
      globalError: i18next.t("verify_error_msg"),
    });
  }
}

export async function getCrewList(dispatch) {
  const apiPath = `${baseAPI}/admin/users`;

  dispatch({
    type: "setCrewListLoading",
    crewListLoading: true,
  });
  try {
    const response = await fetch(apiPath, {
      headers: getHeadersWithAuthToken(),
    });
    const jsonData = await response.json();
    const { content, errors } = jsonData;
    if (!response.ok) {
      handleBadResponse(response, errors, dispatch);
    } else {
      dispatch({
        type: "setCrewList",
        crewList: content,
      });
    }
  } catch (e) {
    handleException(dispatch, e);
    dispatch({
      type: "setGlobalError",
      globalError: i18next.t("request_error_msg"),
    });
  }
  dispatch({
    type: "setCrewListLoading",
    crewListLoading: false,
  });
}

/** Convenience for getting apis that just load some basic data */
export async function getApiByResourceName(
  dispatch,
  resourceName,
  contextName,
  methodType,
  entity,
  queryStringParams
) {
  const actionType = methodType === "get" ? "Loading" : "Saving";
  dispatch({
    type: `set${contextName}${actionType}`,
    value: true,
  });

  let params = !isNullOrUndefined(queryStringParams)
    ? `?${new URLSearchParams(queryStringParams).toString()}`
    : "";

  let entityId = null;
  if (!isNullOrUndefined(entity?.uuid)) {
    entityId = entity.uuid;
  } else if (!isNullOrUndefined(entity?.reg)) {
    entityId = entity.reg;
  }
  // If the entityId is not already in the path, then add to the end
  const apiPath =
    !isBlank(entityId) && resourceName.indexOf(entityId) === -1
      ? `${baseAPI}/${resourceName}/${entityId}${params}`
      : `${baseAPI}/${resourceName}${params}`;

  let result = true;
  try {
    let fetchOpts = {
      headers: getHeadersWithAuthToken(),
    };
    if (!isNullOrUndefined(methodType)) {
      fetchOpts.method = methodType;
    }
    if (
      methodType === "put" ||
      methodType === "post" ||
      methodType === "PATCH"
    ) {
      fetchOpts.body = JSON.stringify(entity);
    }
    let response = await fetch(apiPath, fetchOpts);
    if (response.status === 401) {
      // const refreshToken = localStorage.getItem("refreshToken");
      const refreshToken = CookieManager.getCookieValue("refreshToken");
      // const email = localStorage.getItem("email");
      const email = CookieManager.getCookieValue("email");
      if (!isBlank(refreshToken) && !isBlank(email)) {
        await login(
          {
            email,
            refreshToken,
          },
          dispatch
        );
        fetchOpts = {
          headers: getHeadersWithAuthToken(),
        };
        response = await fetch(apiPath, fetchOpts);
        if (response.status === 401) {
          logout();
        }
      } else {
        logout();
      }
    }

    const jsonData = await response.json();
    const { content, errors } = jsonData;
    const expectResponseData =
      (methodType === "post" || methodType === "put") &&
      (RESOURCES_WITH_RESPONSE_DATA.includes(resourceName) ||
        CONTEXTS_WITH_RESPONSE_DATA.includes(contextName));

    if (!response.ok) {
      handleBadResponse(response, errors, dispatch);
      result = false;
    } else if (methodType === "get" || expectResponseData) {
      dispatch({
        type: `set${contextName}`,
        value: content,
      });
    }
  } catch (e) {
    handleException(dispatch, e);
    if (!RESOURCES_WITH_SILENT.includes(resourceName)) {
      dispatch({
        type: "setGlobalError",
        globalError: i18next.t("request_error_msg"),
      });
    }
    result = false;
  }
  dispatch({
    type: `set${contextName}${actionType}`,
    value: false,
  });
  return result;
}

export function handleException(dispatch, e) {
  console.log(e);
  dispatch({
    type: "setAnalyticsEvent",
    value: {
      eventName: ANALYTICS_EVENTS.CLIENT_ERROR,
      properties: {
        info: `${e?.name}: ${e?.detail}`,
      },
    },
  });
}

export async function getWeather(dispatch) {
  return await getApiByResourceName(dispatch, "weather", "Weather", "get");
}

export async function getUserGroups(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "admin/users/user-groups",
    "UserGroups",
    "get"
  );
}

export async function saveAlertsLastRead(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "user/alerts-last-read",
    "AlertsLastRead",
    "PATCH"
  );
}

export async function getAlerts(dispatch) {
  return await getApiByResourceName(dispatch, "alerts", "Alerts", "get");
}

// Use this only for background fetching of alerts
export async function getBackgroundAlerts(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "alerts",
    "BackgroundAlerts",
    "get"
  );
}

export async function getTurnaroundDetails(dispatch, turnaround) {
  return await getApiByResourceName(
    dispatch,
    "turnarounds",
    "TurnaroundDetails",
    "get",
    turnaround
  );
}
export async function getTurnaroundDetailsForConfirm(dispatch, turnaround) {
  return await getApiByResourceName(
    dispatch,
    "turnarounds",
    "TurnaroundDetailsForConfirm",
    "get",
    turnaround
  );
}

export async function getTurnaroundsSummary(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "turnarounds-summary",
    "TurnaroundsSummary",
    "get"
  );
}

export async function getTurnaroundsWithCriteria(dispatch, criteria) {
  return await getApiByResourceName(
    dispatch,
    "turnarounds-search",
    "TurnaroundsWithCriteria",
    "get",
    null,
    criteria
  );
}

export async function getTurnaroundsPerformance(dispatch, criteria) {
  getApiByResourceName(
    dispatch,
    "reports/on-time-performance",
    "TurnaroundsPerformance",
    "post",
    criteria
  );
}

export async function getTurnaroundsFlightNumberPairs(dispatch, criteria) {
  getApiByResourceName(
    dispatch,
    "turnarounds-flight-number-pairs",
    "TurnaroundsFlightNumberPairs",
    "get",
    null,
    criteria
  );
}

export async function getTurnaroundAllocations(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "turnaround-allocations",
    "TurnaroundAllocations",
    "get"
  );
}

export async function getTurnaroundStandAssignments(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "turnaround-stand-assignments",
    "TurnaroundStandAssignments",
    "get"
  );
}

export async function getVehicleUtilization(dispatch, criteria) {
  getApiByResourceName(
    dispatch,
    "reports/gse-utilization",
    "VehicleUtilization",
    "post",
    criteria
  );
}
export async function getLeaderboard(dispatch, criteria) {
  getApiByResourceName(
    dispatch,
    "reports/user-leaderboard",
    "Leaderboard",
    "post",
    criteria
  );
}
export async function getLeaderboardExport(dispatch, criteria) {
  getApiByResourceName(
    dispatch,
    "reports/user-leaderboard/export",
    "DataExport", // common export
    "post",
    criteria
  );
}
export async function getGseUtilizationExport(dispatch, criteria) {
  getApiByResourceName(
    dispatch,
    "reports/gse-utilization/export",
    "DataExport", // common export
    "post",
    criteria
  );
}
export async function getOnTimePerformanceExport(dispatch, criteria) {
  getApiByResourceName(
    dispatch,
    "reports/on-time-performance/export",
    "DataExport", // common export
    "post",
    criteria
  );
}

export async function getFlights(dispatch) {
  return await getApiByResourceName(dispatch, "flights", "Flights", "get");
}

export async function getVehicles(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "ground-vehicles",
    "Vehicles",
    "get"
  );
}

export async function getUsers(dispatch) {
  return await getApiByResourceName(dispatch, "users", "Users", "get");
}

// These are the admin-able version of Vehicle
export async function getGses(dispatch) {
  return await getApiByResourceName(dispatch, "admin/gse", "Gses", "get");
}

export async function getGseTypes(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "admin/gse/types",
    "GseTypes",
    "get"
  );
}

export async function getCertifications(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "admin/users/certifications",
    "Certifications",
    "get"
  );
}

export async function getDeployments(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "/deployments/search",
    "Deployments",
    "get"
  );
}

export async function getTrackers(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "/admin/gse/trackers",
    "Trackers",
    "get"
  );
}

export async function getPositions(dispatch, criteria) {
  return await getApiByResourceName(
    dispatch,
    "positions",
    "Positions",
    "post",
    criteria,
    null
  );
}

export async function getFlightsToRender(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "flights-to-render",
    "FlightsToRender",
    "get"
  );
}

export async function getAirportDetailByUuid(dispatch, airportEntity) {
  return await getApiByResourceName(
    dispatch,
    "airports",
    "AirportDetail",
    "get",
    airportEntity
  );
}

export async function saveUser(itemValues, dispatch, isDelete = false) {
  let methodType = isDelete
    ? "delete"
    : !isBlank(itemValues.uuid)
    ? "put"
    : "post";

  return await getApiByResourceName(
    dispatch,
    "admin/users",
    "User",
    methodType,
    itemValues
  );
}

export async function removeUser(itemValues, dispatch) {
  return await saveUser(itemValues, dispatch, true);
}

export async function saveVehicle(itemValues, dispatch, isDelete = false) {
  let methodType = isDelete
    ? "delete"
    : !isBlank(itemValues.uuid)
    ? "put"
    : "post";

  return await getApiByResourceName(
    dispatch,
    "admin/gse",
    "Gses",
    methodType,
    itemValues
  );
}

export async function removeVehicle(itemValues, dispatch) {
  return await saveVehicle(itemValues, dispatch, true);
}

export async function updateDeploymentUserGroup(values, dispatch) {
  return await getApiByResourceName(
    dispatch,
    "user/current-deployment-user-group",
    "Deployment",
    "put",
    values
  );
}

export async function changePassword(values, dispatch) {
  return await getApiByResourceName(
    dispatch,
    "change-password",
    "ChangePassword",
    "post",
    values
  );
}

export async function getDynamicEnv(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "dynamic-env",
    "DynamicEnv",
    "get"
  );
}

export async function saveAircraftStand(dispatch, standsRequest) {
  return await getApiByResourceName(
    dispatch,
    "aircrafts",
    "AircraftStand",
    "PATCH",
    standsRequest
  );
}

export async function saveTurnaroundStands(dispatch, standsRequest) {
  return await getApiByResourceName(
    dispatch,
    "turnarounds",
    "TurnaroundStands",
    "PATCH",
    standsRequest
  );
}

export async function getTurnaroundTemplates(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "turnaround-templates",
    "TurnaroundTemplates",
    "get"
  );
}

export async function getProfilePictureUpload(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "settings/profile-picture-upload",
    "ProfilePictureUpload",
    "get"
  );
}

export async function saveTurnaroundProfile(dispatch, turnaroundProfile) {
  return await getApiByResourceName(
    dispatch,
    "turnarounds/profiles",
    "TurnaroundProfile",
    "put",
    turnaroundProfile
  );
}

export async function patchTurnaroundProfile(dispatch, turnaroundProfile) {
  return await getApiByResourceName(
    dispatch,
    "turnarounds/profiles",
    "TurnaroundProfile",
    "PATCH",
    turnaroundProfile
  );
}

export async function patchTurnaroundTemplate(dispatch, turnaroundTemplate) {
  return await getApiByResourceName(
    dispatch,
    "turnaround-templates",
    "TurnaroundTemplate",
    "PATCH",
    turnaroundTemplate
  );
}

export async function getAlertPreferences(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "settings/alert-preferences",
    "AlertPreferences",
    "get"
  );
}
export async function saveAlertPreferences(dispatch, enabledAlertTypes) {
  return await getApiByResourceName(
    dispatch,
    "settings/alert-preferences",
    "AlertPreferences",
    "put",
    enabledAlertTypes
  );
}

export async function getTurnaroundLabelsPreview(dispatch, previewRequest) {
  const resource = `turnarounds/profiles/${previewRequest.uuid}/turnaround-labels-preview`;
  // NOTE: this is a quirk of the API, we don't pass these in on Preview but we need on the Patch
  const entity = {
    labelUuidsToRemove: previewRequest.labelUuidsToRemove,
    parentLabelUuidsToApply: previewRequest.parentLabelUuidsToApply,
  };
  return await getApiByResourceName(
    dispatch,
    resource,
    "TurnaroundLabelsPreview",
    "post",
    entity
  );
}

export async function patchTurnaroundOperationAckStatus(
  dispatch,
  operationUuid
) {
  const resource = `turnaround-operations/${operationUuid}/ack-status`;
  return await getApiByResourceName(
    dispatch,
    resource,
    "TurnaroundOperationAckStatus",
    "PATCH"
  );
}
