import {
  getApiByResourceName,
  handleBadResponse,
  handleException,
} from "./api";
import i18next from "./i18n";

export async function getChannels(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "comms/channels",
    "Channels",
    "get"
  );
}
export async function getCommsSummary(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "comms-summary",
    "CommsSummary",
    "get"
  );
}
export async function getCommsChannel(dispatch, commsChannelId) {
  return await getApiByResourceName(
    dispatch,
    `comms/channels/${commsChannelId}`,
    "CommsChannel",
    "get"
  );
}

export async function getCommsUsers(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "comms/users",
    "CommsUsers",
    "get"
  );
}

export async function getCommsPositions(dispatch, criteria) {
  return await getApiByResourceName(
    dispatch,
    "positions",
    "Positions",
    "post",
    criteria,
    null
  );
}

export async function patchCommsChannelRead(dispatch, channelId) {
  return await getApiByResourceName(
    dispatch,
    `comms/channels/${channelId}/read`,
    "CommsChannelRead",
    "PATCH"
  );
}

export async function postCommsChannelMessage(dispatch, channelId, content) {
  return await getApiByResourceName(
    dispatch,
    `comms/channels/${channelId}/messages`,
    "CommsChannelMessagePost",
    "post",
    content
  );
}

export async function postCommsChannel(dispatch, channel) {
  return await getApiByResourceName(
    dispatch,
    `comms/channels`,
    "CommsChannelPost",
    "post",
    channel
  );
}

export async function putCommsChannel(dispatch, channelId, channel) {
  return await getApiByResourceName(
    dispatch,
    `comms/channels/${channelId}`,
    "CommsChannelPut",
    "put",
    channel
  );
}
export async function getCommsChannelAttachments(dispatch, channelId) {
  return await getApiByResourceName(
    dispatch,
    `comms/channels/${channelId}/attachments`,
    "CommsChannelAttachments",
    "get"
  );
}

export async function getCommsAttachmentUpload(dispatch) {
  return await getApiByResourceName(
    dispatch,
    "comms/attachment-upload",
    "CommsAttachmentUpload",
    "get"
  );
}

export async function uploadCommsAttachment(
  dispatch,
  channelId,
  uploadUrl,
  fileData,
  fileType
) {
  try {
    const response = await fetch(uploadUrl, {
      method: "PUT",
      headers: {
        "Content-Type": fileType,
        "Cache-Control": "no-cache",
      },
      body: fileData,
    });
    await response.blob();
    if (!response.ok) {
      // Check errors?
      handleBadResponse(response, null, dispatch);
    }
  } catch (e) {
    handleException(dispatch, e);
    dispatch({
      type: "setGlobalError",
      globalError: i18next.t("login_user_error_msg"),
    });
  }
}
