import { useMainContext, useMainDispatchContext } from "../../MainContext";
import { getTurnaroundInfo } from "../../turnaroundUtils";
import {
  deepCopy,
  getRelativeTimeString,
  isNullOrUndefined,
  sortByDateField,
} from "../../utils";
import { useEffect, useState } from "react";
import "./styles.css";
import {
  getTurnaroundAllocations,
  getTurnaroundDetails,
  getUsers,
  patchTurnaroundProfile,
} from "../../api";
import DispatchEditor from "./DispatchEditor";
import moment from "moment-timezone";
import { getProfilePatchRequest } from "../../turnaroundProfileUtils";
import { useTranslation } from "react-i18next";
import UpcomingTurnaroundAllocations from "./UpcomingTurnaroundAllocations";
import ActiveTurnaroundAllocations from "./ActiveTurnaroundAllocations";
import NumberIconBadge, { BADGE_TYPE } from "../NumberIconBadge";
import { ANALYTICS_EVENTS, DEFAULT_TIMEZONE } from "../../constants";
import Keys from "../Keys";

// TODO: Rename to Allocations
function Dispatches() {
  const { t } = useTranslation();
  const dispatch = useMainDispatchContext();
  const mainContext = useMainContext();
  const {
    turnaroundAllocations,
    currentUser,
    turnaroundDispatchToEdit,
    turnaroundDetails,
  } = mainContext;

  const [activeTurnarounds, setActiveTurnarounds] = useState([]);
  const [upcomingTurnarounds, setUpcomingTurnarounds] = useState([]);
  const [criticalActiveTurnarounds, setCriticalActiveTurnarounds] = useState(
    []
  );
  const [criticalUpcomingTurnarounds, setCriticalUpcomingTurnarounds] =
    useState([]);
  const [isDispatchRequested, setIsDispatchRequested] = useState(false);
  const [originalTurnaroundInfo, setOriginalTurnaroundInfo] = useState(null);
  const [turnaroundToConfigure, setTurnaroundToConfigure] = useState(null);
  const [turnaroundProfileConfiguration, setTurnaroundProfileConfiguration] =
    useState(null);
  const [showActiveTurnarounds, setShowActiveTurnarounds] = useState(false);

  useEffect(() => {
    getUsers(dispatch);
    getTurnaroundAllocations(dispatch);
    dispatch({
      type: "setAnalyticsEvent",
      value: {
        eventName: ANALYTICS_EVENTS.ALLOCATIONS_PAGE_VIEW,
      },
    });
  }, [dispatch]);

  // Handles editing dispatch from outside pages
  useEffect(() => {
    if (
      isNullOrUndefined(turnaroundDetails) &&
      !isNullOrUndefined(turnaroundDispatchToEdit)
    ) {
      getTurnaroundDetails(dispatch, { uuid: turnaroundDispatchToEdit });
    } else if (
      !isNullOrUndefined(turnaroundDispatchToEdit) &&
      !isNullOrUndefined(turnaroundDetails)
    ) {
      const turnaroundInfo = getTurnaroundInfo(turnaroundDetails);
      setOriginalTurnaroundInfo(turnaroundInfo);
      setTurnaroundToConfigure(turnaroundInfo);
      setTurnaroundProfileConfiguration(
        generateTurnProfileDraft(turnaroundInfo.originalProfile)
      );
      dispatch({
        type: "setTurnaroundDispatchToEdit",
        value: null,
      });
    }
  }, [
    dispatch,
    turnaroundDispatchToEdit,
    turnaroundAllocations,
    turnaroundDetails,
  ]);

  useEffect(() => {
    const activeTurnaroundsList = [];
    const upcomingTurnaroundsList = [];
    const activeCriticalTurnaroundsList = [];
    const upcomingCriticalTurnaroundsList = [];

    if (
      !isNullOrUndefined(turnaroundAllocations) &&
      !isNullOrUndefined(currentUser)
    ) {
      const turnaroundAllocationList = deepCopy(turnaroundAllocations);
      const { airport } = currentUser;
      const airportTimezone = !isNullOrUndefined(airport)
        ? airport.timezone
        : DEFAULT_TIMEZONE;
      const mNow = moment().tz(airportTimezone).startOf("minute");

      for (let i = 0; i < turnaroundAllocationList.length; i++) {
        const turnaroundAllocation = turnaroundAllocationList[i];
        const turnaroundAllocationInfo = {
          ...turnaroundAllocation,
        };
        const mApprovalDeadline = !isNullOrUndefined(
          turnaroundAllocation.approvalDeadline
        )
          ? moment(turnaroundAllocation.approvalDeadline)
              .tz(airportTimezone)
              .startOf("minute")
          : null;
        // NOTE: The approvalDeadline will determine the active state and dispatch time
        turnaroundAllocationInfo.isActive = !isNullOrUndefined(
          mApprovalDeadline
        )
          ? mNow.isAfter(mApprovalDeadline)
          : false;

        // Critical is defined by unallocated crew
        turnaroundAllocationInfo.isCritical =
          turnaroundAllocation?.numUnallocatedCrew > 0;

        if (turnaroundAllocationInfo.isActive) {
          if (turnaroundAllocationInfo.isCritical) {
            activeCriticalTurnaroundsList.push(turnaroundAllocationInfo);
          } else {
            activeTurnaroundsList.push(turnaroundAllocationInfo);
          }
        } else {
          if (turnaroundAllocationInfo.isCritical) {
            upcomingCriticalTurnaroundsList.push(turnaroundAllocationInfo);
          } else {
            upcomingTurnaroundsList.push(turnaroundAllocationInfo);
          }
        }
      }
      sortByDateField(activeTurnaroundsList, "gateInTime");
      activeTurnaroundsList.reverse();
      sortByDateField(upcomingTurnaroundsList, "isCritical");
      upcomingTurnaroundsList.reverse();
      sortByDateField(activeCriticalTurnaroundsList, "gateInTime");
      activeCriticalTurnaroundsList.reverse();
      sortByDateField(upcomingCriticalTurnaroundsList, "isCritical");
      upcomingCriticalTurnaroundsList.reverse();
    }
    setActiveTurnarounds(activeTurnaroundsList);
    setUpcomingTurnarounds(upcomingTurnaroundsList);
    setCriticalActiveTurnarounds(activeCriticalTurnaroundsList);
    setCriticalUpcomingTurnarounds(upcomingCriticalTurnaroundsList);
  }, [currentUser, turnaroundAllocations]);

  // Sets the turnaround dispatch after loading the full TurnaroundDetails
  useEffect(() => {
    if (!isNullOrUndefined(turnaroundDetails)) {
      const turnaroundInfo = getTurnaroundInfo(turnaroundDetails);
      setOriginalTurnaroundInfo(turnaroundInfo);
      setTurnaroundToConfigure(turnaroundInfo);
      setTurnaroundProfileConfiguration(
        generateTurnProfileDraft(turnaroundInfo.originalProfile)
      );

      // Cleanup
      dispatch({
        type: "setTurnaroundDetails",
        value: null,
      });
    }
  }, [dispatch, turnaroundDetails]);

  if (isNullOrUndefined(currentUser)) return;
  const { airport } = currentUser;
  const airportTimezone = !isNullOrUndefined(airport)
    ? airport.timezone
    : DEFAULT_TIMEZONE;

  // Load the TurnaroundDetails
  const handleConfigureDispatch = (turnaroundUuid) => {
    getTurnaroundDetails(dispatch, { uuid: turnaroundUuid });
    dispatch({
      type: "setAnalyticsEvent",
      value: {
        eventName: ANALYTICS_EVENTS.EDIT_ALLOCATION_BUTTON_CLICK,
      },
    });
  };

  function handleResetDispatches() {
    setTurnaroundToConfigure(null);
    setTurnaroundProfileConfiguration(null);
  }

  async function handleApproveDispatch(turnaroundUuid, profileToUpdate) {
    if (isNullOrUndefined(turnaroundUuid)) return;
    if (isNullOrUndefined(turnaroundAllocations)) return;

    if (!isNullOrUndefined(originalTurnaroundInfo)) {
      setIsDispatchRequested(true);
      dispatch({
        type: "setAnalyticsEvent",
        value: {
          eventName: ANALYTICS_EVENTS.SAVE_ALLOCATION_BUTTON_CLICK,
        },
      });
      // The act of saving should also approve/dispatch
      let result = null;
      if (isNullOrUndefined(profileToUpdate)) {
        profileToUpdate = originalTurnaroundInfo.originalProfile;
      }
      const profilePatchRequest = getProfilePatchRequest(
        originalTurnaroundInfo.originalProfile,
        profileToUpdate
      );
      result = await patchTurnaroundProfile(dispatch, profilePatchRequest);

      if (result) {
        let dispatchMessage = `${t("saved_web", {
          name: originalTurnaroundInfo.combinedFlightName,
        })}`;
        if (!isNullOrUndefined(originalTurnaroundInfo.approvalDeadline)) {
          const mNow = moment().tz(airportTimezone);
          const mApprovalDeadline = moment(
            originalTurnaroundInfo.approvalDeadline
          ).tz(airportTimezone);
          const isPastDeadline = mNow.isAfter(mApprovalDeadline);
          const automaticDispatchTime = `${getRelativeTimeString(
            mApprovalDeadline,
            mNow
          )}`;
          dispatchMessage = isPastDeadline
            ? `${t("crew_will_be_notified_immediately_web", {
                name: originalTurnaroundInfo.combinedFlightName,
              })}`
            : `${t("crew_will_be_notified_in_web", {
                name: originalTurnaroundInfo.combinedFlightName,
                value: automaticDispatchTime,
              })}`;
        }
        getTurnaroundAllocations(dispatch);
        dispatch({
          type: "setAlertMessage",
          alertMessage: dispatchMessage,
        });
        handleResetDispatches();
      }
      setIsDispatchRequested(false);
    }
  }

  const ctrlKeyActions = {
    a: () => {
      // Active turnarounds are hidden, but can turn on with this hotkey
      setShowActiveTurnarounds((prev) => !prev);
    },
  };
  return (
    <div className="dispatches">
      <div
        className={`dispatches-container${
          isNullOrUndefined(turnaroundToConfigure) ? " listing" : ""
        }`}
      >
        <div className="dispatches-header-row">
          <div>
            <h3>{t("turnaround_allocations")}</h3>
          </div>
        </div>
        {isNullOrUndefined(turnaroundToConfigure) && (
          <>
            {showActiveTurnarounds && (
              <div className="dispatches-content active">
                <h4>
                  <div>{t("active_turnarounds")}</div>
                  <div>
                    {criticalActiveTurnarounds.length > 0 && (
                      <NumberIconBadge
                        type={BADGE_TYPE.CRITICAL}
                        value={criticalActiveTurnarounds.length}
                      />
                    )}
                  </div>
                </h4>
                <div className="listing">
                  <div className="listing-header">
                    <div>{t("turnaround")}</div>
                    <div>{t("status")}</div>
                    <div>{t("gate_in_non_cap")}</div>
                    <div>{t("automatic_dispatch")}</div>
                    <div>{t("action")}</div>
                  </div>
                  {activeTurnarounds.length === 0 &&
                    criticalActiveTurnarounds.length === 0 && (
                      <div className="listing-empty">
                        <div>{t("turnarounds_empty")}</div>
                      </div>
                    )}

                  {criticalActiveTurnarounds.length > 0 &&
                    criticalActiveTurnarounds.map((item) => (
                      <ActiveTurnaroundAllocations
                        item={item}
                        key={item.uuid}
                        approveAction={() => {
                          handleApproveDispatch(item.uuid);
                        }}
                        configureAction={() => {
                          handleConfigureDispatch(item.uuid);
                        }}
                        actionsDisabled={isDispatchRequested}
                        currentUser={currentUser}
                      />
                    ))}
                  {activeTurnarounds.length > 0 &&
                    activeTurnarounds.map((item) => (
                      <ActiveTurnaroundAllocations
                        item={item}
                        key={item.uuid}
                        approveAction={() => {
                          handleApproveDispatch(item.uuid);
                        }}
                        configureAction={() => {
                          handleConfigureDispatch(item.uuid);
                        }}
                        actionsDisabled={isDispatchRequested}
                        currentUser={currentUser}
                      />
                    ))}
                </div>
              </div>
            )}
            <div className="dispatches-content upcoming">
              <h4>
                <div>{t("upcoming_turnarounds")}</div>
                <div>
                  {criticalUpcomingTurnarounds.length > 0 && (
                    <NumberIconBadge
                      type={BADGE_TYPE.CRITICAL}
                      value={criticalUpcomingTurnarounds.length}
                    />
                  )}
                </div>
              </h4>

              <div className="listing">
                <div className="listing-header">
                  <div>{t("turnaround")}</div>
                  <div>{t("status")}</div>
                  <div>{t("gate_in_non_cap")}</div>
                  <div>{t("automatic_dispatch")}</div>
                  <div>{t("action")}</div>
                </div>
                {upcomingTurnarounds.length === 0 &&
                  criticalUpcomingTurnarounds.length === 0 && (
                    <div className="listing-empty">
                      <div>{t("turnarounds_empty")}</div>
                    </div>
                  )}
                {criticalUpcomingTurnarounds.length > 0 &&
                  criticalUpcomingTurnarounds.map((item) => (
                    <UpcomingTurnaroundAllocations
                      item={item}
                      key={item.uuid}
                      configureAction={() => {
                        handleConfigureDispatch(item.uuid);
                      }}
                      actionsDisabled={isDispatchRequested}
                      currentUser={currentUser}
                    />
                  ))}
                {upcomingTurnarounds.length > 0 &&
                  upcomingTurnarounds.map((item) => (
                    <UpcomingTurnaroundAllocations
                      item={item}
                      key={item.uuid}
                      configureAction={() => {
                        handleConfigureDispatch(item.uuid);
                      }}
                      actionsDisabled={isDispatchRequested}
                      currentUser={currentUser}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
        {!isNullOrUndefined(turnaroundToConfigure) && (
          <DispatchEditor
            turnaroundProfileConfiguration={turnaroundProfileConfiguration}
            turnaroundToConfigure={turnaroundToConfigure}
            approveAction={(profileToUpdate) => {
              handleApproveDispatch(
                turnaroundToConfigure.uuid,
                profileToUpdate
              );
            }}
            cancelAction={() => {
              handleResetDispatches();
            }}
          />
        )}
      </div>
      <Keys ctrlKeyActions={ctrlKeyActions} />
    </div>
  );
}

function generateTurnProfileDraft(originalProfile) {
  if (isNullOrUndefined(originalProfile)) return null;
  const draftProfile = { ...originalProfile };
  const profileOperations = draftProfile.turnaroundOperations;
  for (let i = 0; i < profileOperations.length; i++) {
    const operation = profileOperations[i];
    const operationRequirements = operation.turnaroundRequirements;
    for (let j = 0; j < operationRequirements.length; j++) {
      populateCrewAssignments(operationRequirements[j]);
    }
  }
  return draftProfile;
}

// Fills the crew assignments with placeholders
function populateCrewAssignments(requirement) {
  const crewAssignments = requirement.crewAssignments;
  if (crewAssignments.length < requirement.quantity) {
    const crewNeeded = requirement.quantity - crewAssignments.length;
    for (let i = 0; i < crewNeeded; i++) {
      crewAssignments.push({
        uuid: null,
        user: null,
        acceptedAt: null,
      });
    }
  }
}

export default Dispatches;
