import { useTranslation } from "react-i18next";
import { formatTime, isNullOrUndefined } from "../../utils";
import CrewInfo from "../CrewInfo";
import { getUserInfo } from "../../userUtils";
import { useMainContext } from "../../MainContext";
import StatusBadge from "../StatusBadge";
function TurnaroundCrew(props) {
  const {
    crewInfo,
    airportTimezone,
    selected,
    isSelectable,
    onClick,
    showCertifications,
    showActions,
    showCrewStatus,
    showAssignCrew,
    onAssignCrew,
    showUnassignCrew,
    onUnassignCrew,
    isSavingUnassignment,
    isUnassigned,
  } = props;
  const mainContext = useMainContext();
  const { positions, turnarounds } = mainContext;
  if (isNullOrUndefined(crewInfo?.user)) return null;
  const userInfo = getUserInfo(crewInfo.user, turnarounds, positions);

  return (
    <div
      className={`turnaround-crew${selected ? " selected" : ""}${
        isSelectable ? " selectable" : ""
      }`}
      key={userInfo?.user.uuid}
      data-uuid={userInfo?.user.uuid}
      onClick={() => {
        if (isSelectable) {
          onClick();
        }
      }}
    >
      {userInfo && userInfo.user && (
        <CrewInfo
          user={userInfo.user}
          showActions={showActions}
          showAssignCrew={showAssignCrew}
          onAssignCrew={onAssignCrew}
          showUnassignCrew={showUnassignCrew}
          onUnassignCrew={onUnassignCrew}
          isSavingUnassignment={isSavingUnassignment}
          details={
            <CrewDetails
              crewInfo={crewInfo}
              airportTimezone={airportTimezone}
              showCertifications={showCertifications}
              showCrewStatus={showCrewStatus}
              isUnassigned={isUnassigned}
            />
          }
          mapStatus={userInfo.mapStatus}
        />
      )}
    </div>
  );
}

// Content to insert into the "details" for the CrewInfo component
function CrewDetails(props) {
  const { crewInfo, airportTimezone, showCertifications, isUnassigned } = props;
  const { t } = useTranslation();
  if (isNullOrUndefined(crewInfo)) return null;
  const hasInitiated = !isNullOrUndefined(crewInfo?.userRecord?.startTime);
  const hasCompleted = !isNullOrUndefined(crewInfo?.userRecord?.endTime);
  const hasAccepted = !isNullOrUndefined(crewInfo?.acceptedAt);
  const hasDispatched = !isNullOrUndefined(crewInfo?.dispatchedAt);

  const departedAt = hasCompleted
    ? formatTime(crewInfo?.userRecord?.endTime, airportTimezone)
    : null;
  const arrivedAt = hasInitiated
    ? formatTime(crewInfo?.userRecord?.startTime, airportTimezone)
    : null;
  const acceptedAt = hasAccepted
    ? formatTime(crewInfo?.acceptedAt, airportTimezone)
    : null;
  const dispatchedAt = hasDispatched
    ? formatTime(crewInfo?.dispatchedAt, airportTimezone)
    : null;

  // Controls what timestamps to show, should show at most 2 at a time
  const showPendingAcceptance = hasDispatched && !hasAccepted;
  const showDispatchedAt = hasDispatched && !hasInitiated;
  const showAcceptedAt = hasAccepted && !hasCompleted;
  const showArrivedAt = hasInitiated;
  const showDepartedAt = hasInitiated && hasCompleted;

  return (
    <>
      {!isUnassigned && showCertifications && (
        <div>
          <div className="certification-name">
            <div>{crewInfo.certificationName}</div>
          </div>
        </div>
      )}
      {isUnassigned && (
        <div>
          <StatusBadge
            message={t("unassigned").toUpperCase()}
            isCritical={true}
          />
        </div>
      )}
      {showPendingAcceptance && (
        <span className="pending">{`${t("pending_acceptance")}`}</span>
      )}
      {showDispatchedAt && (
        <span>
          {`${t("dispatched_cap_colon")}`}&nbsp;
          <span className="time-completed">{dispatchedAt}</span>
        </span>
      )}
      {showAcceptedAt && (
        <span>
          {t("accepted_cap_colon")}&nbsp;
          <span className="time-completed">{acceptedAt}</span>
        </span>
      )}
      {showArrivedAt && (
        <span>
          {t("arrived_cap_colon")}&nbsp;
          <span className="time-completed">{arrivedAt}</span>
        </span>
      )}

      {showDepartedAt && (
        <span>
          {t("departed_cap_colon")}&nbsp;
          <span className="time-completed">{departedAt}</span>
        </span>
      )}
    </>
  );
}

export default TurnaroundCrew;
